import React, { useState, useEffect, Component, useCallback } from "react";

import * as Mui from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ProductService from "../../services/products";
import { UserContext } from "../../context/UserContext";
import TeacherService from "../../services/teacher";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { useForm } from "../common/useForm";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  TextField,
  Box,
  FormControl,
  MenuItem,
  Select,
  FormControlLabel,
  Typography,
  FormHelperText,
  Checkbox,
  InputAdornment,
  InputLabel,
  Tooltip,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import "../css/teacherReg.css";
import { Redirect, Link } from "react-router-dom";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ReactPlayer from "react-player";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slider from "@material-ui/core/Slider";
import PostLoader from "../shared/loaders/postLoader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import Switch from "react-switch";
import Product from "../../services/products";
import { useDropzone } from "react-dropzone";
import CoverCropper from "../shared/ImageUploader/CoverCropper";
import AWS from "aws-sdk";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";
import { useMediaQuery } from "react-responsive";
// import { ToastContainer, toast } from 'material-react-toastify';
// import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FileUsage from "../products/fileUsage";

const fs = require("fs");

const useStyles = makeStyles((theme) => ({
  redText: {
    color: "red",
  },
  LinearProgressClass: {
    backgroundColor: "#dbebe7",
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#5d9767",
    },
  },
  cartWrapper: {
    backgroundColor: "white",
    marginTop: 50,
    marginBottom: 50,
    padding: 20,
    borderRadius: 15,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  pageTitles: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 5,
    },
    color: "#E48F2B",
  },
  productAdd: {
    "& .MuiFormControl-root": {
      width: "100%",
      marginBottom: theme.spacing(1),
    },
    "& .MuiGrid-spacing-xs-1": {
      margin: "0px",
    },
    "& .MuiInputLabel-outlined": {
      zIndex: "0",
    },
    "& .MuiFormHelperText-root": {
      color: "#f44336",
      fontSize: "0.75rem",
    },
  },
  buttonSpacing: {
    width: "20%",
    backgroundColor: "#E48F2B",
    color: "white",
    borderColor: "transparent",
    "&:hover": {
      backgroundColor: "#c4771d",
      color: "white",
      borderColor: "transparent",
    },
  },
  btnWidth: {
    width: "30%",
  },
  coverDropzone: {
    maxHeight: theme.spacing(14),
    minHeight: theme.spacing(14),
    "& .MuiDropzoneArea-icon": {
      display: "none",
    },
  },
  materialDropzone: {
    maxHeight: theme.spacing(42),
    minHeight: theme.spacing(42),
    "& .MuiDropzoneArea-icon": {
      display: "none",
    },
  },
  fontSizeDropzone: {
    fontSize: "16px",
  },
  actionButtonItems: {
    display: "flex",
    justifyContent: "flex-end",
  },
  coverPhotoSection: {
    width: "100%",
    // height: theme.spacing(40),
    marginBottom: 20,
    background: "#f1f1f1",
    border: "dashed",
    borderWidth: "1px",
    overflow: "hidden",
    padding: "0",
  },
  materialPhotoSection: {
    width: theme.spacing(35),
    height: theme.spacing(40),
    background: "rgb(227, 227, 227)",
    border: "dashed",
    borderWidth: "1px",
    overflow: "hidden",
    padding: "0",
  },
  uploadwrapper: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: theme.spacing(0.5),
  },
  documentwrapper: {
    height: "65%",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: theme.spacing(0.5),
    position: "relative",
    "& .uploadButtonLabel": {
      width: "100%",
      display: "block",
      position: "absolute",
      bottom: "0",
      [theme.breakpoints.down("sm")]: {
        position: "relative",
      },
    },
    "& .MuiButton-outlined": {
      display: "block",
      margin: theme.spacing(0.5),
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },
  },
  documentwrapperMobile: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: theme.spacing(0.5),
    position: "relative",
    "& .uploadButtonLabel": {
      width: "100%",
      display: "block",
      position: "absolute",
      bottom: "0",
      [theme.breakpoints.down("sm")]: {
        position: "relative",
      },
    },
    "& .MuiButton-outlined": {
      display: "block",
      margin: theme.spacing(0.5),
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },
  },
  creatableSelect: {
    minHeight: theme.spacing(7),
    "& > div:nth-of-type(1)": {
      minHeight: theme.spacing(7),
    },
  },
  selectPlaeholder: {
    "& em": {
      color: "hsl(0, 0%, 50%)",
      fontStyle: "normal",
    },
  },
  errorFieldPadding: {
    paddingTop: "1px !important",
    "& .MuiFormHelperText-root": {
      marginTop: "0px",
    },
  },
  fileWrapper: {
    height: "60vh",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  itemContainer: {
    padding: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  marginRight: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  qualificationInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "98%",
    height: "100%",
    marginTop: 7,
  },
  btnAdjustment: {
    width: "98%",
    height: theme.spacing(9),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiButton-label": {
      width: "100%",
      textAlign: "center",
    },
  },
  coverWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  pictureIcon: {
    marginBottom: 10,
    color: "#acacac",
    fontSize: theme.spacing(4),
  },
  noMaterialAttached: {
    marginTop: theme.spacing(5),
    textAlign: "center",
    color: "#A1A1A1",
  },
  documentsScroller: {
    overflowY: "scroll",
    height: "82%",
  },
  closeIcon: {
    position: "absolute",
    top: 10,
    color: "#ce2727",
    right: 23,
    border: "solid 1px #ce2727",
    borderRadius: 20,
    cursor: "pointer",
  },
  switchWrapper: {
    "& svg": {
      display: "none",
    },
  },
  uploadProgress: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#2d8d2d",
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "rgb(202 229 202)",
    },
  },
  fileUsageWrapper: {
    marginTop: "20px",
  },
}));
const generatePassCode = (length) => {
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var result = "";
  var chaactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * chaactersLength));
  }
  return result;
};
const addProductFields = {
  courseId: "",
  productName: "",
  productAccessCode: "",
  productTags: "",
  productCategory: "",
  productGrad: "",
  productLevel: "",
  price: "",
  discountedPrice: "",
  expiryDate: "",
  worksheetPreviewMode: "",
  productDescription: "",
  watermarks: "",
  showWaterMark: "",
};

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
        <span className={classes.redText}>*</span>
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

function PreviewModal(props) {
  const [currentPreviewStarting, setCurrentPreviewStarting] =
    React.useState(null);
  const [currentPreviewEnding, setCurrentPreviewEnding] = React.useState(null);
  const [previewDuration, setPreviewDuration] = React.useState(0);

  const handleDuration = (duration) => {
    var time = new Date(duration * 1000).toISOString().substr(11, 8);
    setPreviewDuration(duration);
  };

  const getDuration = () => {
    return previewDuration;
  };

  React.useEffect(() => {
    setCurrentPreviewStarting(props.starts);
    setCurrentPreviewEnding(props.ends);
    console.log(currentPreviewEnding);
  }, []);

  const handlePreviewChange = (value) => {
    console.log(value);
    setCurrentPreviewStarting(value[0]);
    setCurrentPreviewEnding(value[1]);

    // setCurrentPreviewEnding(props.ends)
  };

  const handleSavePreview = () => {
    props.handleUpdatePreview(
      props.index,
      new Date(currentPreviewStarting * 1000).toISOString().substr(11, 8),
      new Date(currentPreviewEnding * 1000).toISOString().substr(11, 8)
    );
    props.handlePreviewModalClose();
  };
  let starting = currentPreviewStarting;
  let ending = currentPreviewEnding;
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={props.openPreviewModal}
      onClose={props.handlePreviewModalClose}
    >
      <DialogTitle>Select Preview</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} sm={7}>
            <ReactPlayer
              playing={false}
              controls={true}
              className="react-player"
              url={
                props.path == null
                  ? process.env.REACT_APP_FOLDER_URL +
                    process.env.REACT_APP_MATERIAL_PATH +
                    "material/" +
                    props.previewName
                  : props.path
              }
              width="100%"
              volume={100 / 100}
              onDuration={handleDuration}
              height="auto"
            />
          </Grid>
          <Grid item xs={12} sm={5} className="time-details">
            {currentPreviewStarting != null && currentPreviewEnding != null && (
              <>
                <h4>{props.previewTitle}</h4>
                {isNaN(currentPreviewStarting) ? (
                  <>
                    <p className="schedule">
                      <Icon>schedule</Icon> Preview starting time:{" "}
                      <strong> {currentPreviewStarting}</strong>
                    </p>
                    <p className="schedule">
                      <Icon>schedule</Icon> Preview ending time:{" "}
                      <strong> {currentPreviewEnding}</strong>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="schedule">
                      <Icon>schedule</Icon> Preview starting time:{" "}
                      <strong>
                        {" "}
                        {new Date(currentPreviewStarting * 1000)
                          .toISOString()
                          .substr(11, 8)}
                      </strong>
                    </p>
                    <p className="schedule">
                      <Icon>schedule</Icon> Preview ending time:{" "}
                      <strong>
                        {" "}
                        {new Date(currentPreviewEnding * 1000)
                          .toISOString()
                          .substr(11, 8)}
                      </strong>
                    </p>
                  </>
                )}

                <p>Some explaination text</p>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={7}>
            {previewDuration != 0 &&
              currentPreviewStarting != null &&
              currentPreviewEnding != null && (
                <PreviewSlider
                  handlePreviewChange={handlePreviewChange}
                  starts={currentPreviewStarting}
                  ends={currentPreviewEnding}
                  totalTime={previewDuration}
                />
              )}

            {!previewDuration && (
              <PreviewSlider
                handlePreviewChange={handlePreviewChange}
                starts={currentPreviewStarting}
                ends={currentPreviewEnding}
                totalTime={40}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handlePreviewModalClose}>Close</Button>
        <Button className="save-btn" onClick={() => handleSavePreview()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function PreviewSlider(props) {
  const [previewRange, setPreviewRange] = React.useState([0, 20]);
  const [maxPreview, setMaxPreview] = React.useState();

  const handlePreviewRange = (event, newValue) => {
    console.log(newValue);
    if (newValue[1] - newValue[0] < 21) {
      setPreviewRange(newValue);
      console.log(newValue);
      props.handlePreviewChange(newValue);
    }
  };

  function valuetext(value) {
    return new Date(value * 1000).toISOString().substr(11, 8);
  }

  React.useEffect(() => {
    setMaxPreview(props.totalTime);
    console.log(props.totalTime);
    console.log(props.starts, props.ends);
    var starting = props.starts; // your input string
    if (isNaN(props.starts)) {
      var a = starting.split(":");
      var startingseconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    } else {
      var startingseconds = starting;
    }

    var ending = props.ends; // your input string
    if (isNaN(props.ends)) {
      var a = ending.split(":");
      var endingseconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    } else {
      var endingseconds = ending;
    }

    if (props.totalTime > 20) {
      setPreviewRange([startingseconds, endingseconds]);
      props.handlePreviewChange([props.starts, props.ends]);
    } else {
      setPreviewRange([startingseconds, endingseconds]);
      props.handlePreviewChange([props.starts, props.ends]);
    }
  }, []);

  return (
    <Slider
      onChange={handlePreviewRange}
      value={previewRange}
      max={maxPreview}
    />
  );
}

export default function AddProduct(props) {
  const [check, setCheck] = React.useState("<p>Hello</p>");
  const {
    setEditCourseCover,
    editCourseCover,
    handleMaterialItems,
    editMaterialItems,
    recordForEdit,
    addEditProduct,
    handleProductListUpdate,
    handleAddProductPopupClose,
    addProductPopup,
  } = props;
  const classes = useStyles();
  const [playingVideo, playVideo] = React.useState(false);
  const [addProductTags, setAddProductTags] = React.useState([]);
  const [addProductLevels, setAddProductLevels] = React.useState([]);
  const [addProductCategory, setAddProductCategory] = React.useState([]);
  const [addProductGrad, setAddProductGrad] = React.useState([]);
  const [productCover, setProductCover] = React.useState(null);
  const [newProductCover, setNewProductCover] = React.useState(null);
  const [productExpires, setProductExpires] = React.useState("no");
  const [addWaterMark, setAddWaterMark] = React.useState("0");
  const [availableWaterMark, setAvailableWaterMark] = React.useState([]);
  const [material, setMaterial] = React.useState([]);
  const [invalid, setInvalidNames] = React.useState([]);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [materialList, setMaterialList] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [levels, setLevels] = React.useState(null);
  const [subjects, setSubjects] = React.useState(null);
  const [freeProduct, setFreeProduct] = React.useState("no");
  const [productLevelsArray, setProductLevelsArray] = React.useState();
  const [previewStartsAt, setPreviewStartsAt] = React.useState();
  const [previewEndsAt, setPreviewEndsAt] = React.useState();
  const [previewPath, setPreviewPath] = React.useState(null);
  const [deletedMaterialList, setDeletedMaterialList] = React.useState([]);
  const addProductWorksheetMode = [
    { value: "low", label: "All pages in low resolution" },
    { value: "high", label: "Only first page in high resolution" },
  ];
  const [openPreviewModal, setOpenPreviewModal] = React.useState(false);
  const [previewTitle, setPreviewTitle] = React.useState(null);
  const [previewName, setPreviewName] = React.useState(null);
  const [showProfileCropper, setShowProfileCropper] = React.useState(false);
  const [selectedProdCatVal, setSelectedProdCatVal] = React.useState(null);
  const [selectedProdGradeVal, setSelectedProdGradeVal] = React.useState(null);
  const [selectedProdLevelVal, setSelectedProdLevelVal] = React.useState([]);
  const [previewIndex, setPreviewIndex] = React.useState(null);
  const [selectedProdTagsVal, setSelectedProdTagsVal] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [passCode, setPassCode] = React.useState("");
  const [originalSubject, setOriginalSubject] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );
  const [progresses, setProgress] = useState(0);
  const [uploadingName, setUploadingName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const { user, token } = React.useContext(UserContext);
  const [currentUser, setCurrentUser] = user;
  const [canSale, setCanSale] = React.useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    let newSlides = [];
    acceptedFiles.map((slide) => {
      let file = {
        file: slide,
        url: URL.createObjectURL(slide),
        type: slide.type,
        name: slide.name,
      };
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const linkCopied = () => toast.success("URL copied!");
  const bothCopied = () => toast.success("URL and access copied!");
  const codeCopied = () => toast.success("Access code copied!");

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      process.env.REACT_APP_FOLDER_URL +
        btoa(
          props.productID.split("").reverse().join("") + "," + props.productID
        )
    );
    linkCopied();
  };
  const handleSaveWaterMark = () => {
    toast.success("Watermark Saved!");
  };
  const handleRemveWaterMark = (item) => {
    const filtereAvailableWaterMark = availableWaterMark?.filter(
      (e) => e.id !== item.id
    );
    setAvailableWaterMark(filtereAvailableWaterMark);
    const watermarks = filtereAvailableWaterMark
      .map((watermark) => {
        return `${watermark.watermarkCode},${watermark.value}`;
      })
      .join("|");
    setProductValueData({
      ...productValueData,
      watermarks: watermarks,
    });
  };
  const handleAddInput = () => {
    setAvailableWaterMark([
      ...availableWaterMark,
      {
        id: availableWaterMark.length + 1,
        value: "",
        watermarkCode: generatePassCode(6),
      },
    ]);
  };
  const handleInputChangeForWaterMark = (index, event) => {
    const updatedWatermarks = [...availableWaterMark];
    updatedWatermarks[index].value = event.target.value;
    setAvailableWaterMark(updatedWatermarks);
    const watermarks = availableWaterMark
      .map((watermark) => {
        return `${watermark.watermarkCode},${watermark.value}`;
      })
      .join("|");
    setProductValueData({
      ...productValueData,
      watermarks: watermarks,
    });
  };
  const handleCopyBoth = () => {
    let text =
      "Course URL: " +
      process.env.REACT_APP_FOLDER_URL +
      btoa(
        props.productID.split("").reverse().join("") + "," + props.productID
      ) +
      "\nAccess code: " +
      passCode;
    navigator.clipboard.writeText(text);
    bothCopied();
  };
  const handleCopyBothForWaterMark = (code) => {
    let text =
      "Course URL: " +
      process.env.REACT_APP_FOLDER_URL +
      btoa(
        props.productID.split("").reverse().join("") + "," + props.productID
      ) +
      "\nAccess code: " +
      code;
    navigator.clipboard.writeText(text);
    bothCopied();
  };
  const handleCopyCode = () => {
    navigator.clipboard.writeText(passCode);
    codeCopied();
  };
  const handleCopyCodeForWaterMark = (code) => {
    navigator.clipboard.writeText(code);
    codeCopied();
  };

  React.useEffect(() => {
    resetForm();
  }, [addProductPopup]);

  React.useEffect(() => {
    // Verify User Connect Account
    TeacherService.getConnectDetails(currentUser.user_id).then((res) => {
      console.log(res);
      setCanSale(res.data);
    });
    ProductService.getCourseLevels().then((res) => {
      console.log(res.data);
      // setLevels(res.data)
    });
    ProductService.getSubjects().then((res) => {
      setSubjects(res.data);
      if (recordForEdit != null) {
        let selectedSubject = res.data.filter(
          (subject) => subject.title == recordForEdit.productCategory
        );
        console.log(selectedSubject);
        setLevels(selectedSubject[0].levels);
      }
    });

    if (recordForEdit != null) {
      if (recordForEdit.productAccessCode == "FREECOURSE") {
        setFreeProduct("yes");
      } else {
        setPassCode(props.accessCode);
      }
      const prodTagCollection = JSON.parse(recordForEdit.productTags);
      setSelectedProdTagsVal(prodTagCollection);
      const newTagNamesCollection = [];
      prodTagCollection.map((item) => {
        newTagNamesCollection.push(item.value);
      });
      recordForEdit.productTags = newTagNamesCollection.toString();
      setProductValueData({
        ...recordForEdit,
      });
      setMaterialList(editMaterialItems);
      setSelectedProdCatVal(recordForEdit.productCategory);
      setOriginalSubject(recordForEdit.productCategory);
      setSelectedProdGradeVal({
        label: recordForEdit.productGrad,
        value: recordForEdit.productGrad,
      });
      if (recordForEdit.productLevel != null) {
        // console.log(recordForEdit.productLevel)
        setSelectedProdLevelVal(JSON.parse(recordForEdit.productLevel));
      }

      if (
        recordForEdit.expiryDate != null &&
        recordForEdit.expiryDate != "null"
      ) {
        setProductExpires("yes");
      }
      // console
    } else {
      // setPassCode(generatePassCode(6))
      console.log(productValueData);
      // productValueData.productAccessCode = passCode;
      // setProductValueData(productValueData)
      // setProductValueData(...productValueData, {
      //     "productAccessCode": passCode
      // })
    }

    ProductService.getTags().then((res) => {
      console.log(res.data);
      setAddProductTags(
        res.data.map((tag) => {
          return {
            value: tag.tag,
            label: tag.tag,
          };
        })
      );
    });

    ProductService.getLevels().then((res) => {
      setAddProductLevels(res.data);
    });

    // ProductService.getCategory().then((res) => {
    //     console.log(res.data)
    //     setAddProductCategory(res.data)
    // })
    let categories = [
      { value: "Chinese", label: "Chinese" },
      { value: "English", label: "English" },
      { value: "Math", label: "Math" },
      { value: "Others", label: "Others" },
    ];
    // setAddProductCategory(categories)

    ProductService.getProductGrad().then((res) => {
      setAddProductGrad(res.data);
    });
    setAddWaterMark(recordForEdit?.showWaterMark);
    if (
      recordForEdit?.watermarks?.length > 0 &&
      recordForEdit?.watermarks !== ""
    ) {
      const pairs = recordForEdit?.watermarks?.includes("|")
        ? recordForEdit?.watermarks?.split("|")
        : [recordForEdit?.watermarks];
      const result = pairs.map((pair, index) => {
        const [watermarkCode, value] = pair.split(",");
        return { id: Date.now() + index, watermarkCode, value };
      });
      setAvailableWaterMark(result);
    }
  }, [recordForEdit]);

  const {
    productValueData,
    setProductValueData,
    validationsErrors,
    setValidationsErrors,
    handleInputChange,
    resetForm,
  } = useForm(addProductFields);
  const validateProductForm = () => {
    let validations = {};
    console.log(productCover, editCourseCover);
    const re = /^[0-9\b]+$/;
    validations.productName = productValueData.productName
      ? ""
      : "This field is required";
    // validations.productAccessCode = productValueData.productAccessCode ? "" : "Access code is required";
    validations.productCategory = productValueData.productCategory
      ? ""
      : "This field is required";
    validations.productLevel = productValueData.productLevel
      ? ""
      : "This field is required";
    validations.productTags =
      productValueData.productTags || productValueData.productTags.length > 0
        ? ""
        : "This field is required";
    validations.productGrad = productValueData.productGrad
      ? ""
      : "This field is required";
    validations.worksheetPreviewMode = productValueData.worksheetPreviewMode
      ? ""
      : "This field is required";
    validations.price =
      freeProduct == "yes" || productValueData?.showWaterMark == 1
        ? ""
        : productValueData.price || productValueData.productAccessCode
        ? re.test(productValueData.price) || productValueData.productAccessCode
          ? ""
          : "Price should only be numeric."
        : "Product price or access code is required";
    validations.productAccessCode =
      freeProduct == "yes" || productValueData?.showWaterMark == 1
        ? ""
        : productValueData.price ||
          productValueData.productAccessCode ||
          addWaterMark == "1"
        ? ""
        : "Access code or product price is required";
    validations.discountedPrice = productValueData.discountedPrice
      ? re.test(productValueData.discountedPrice)
        ? ""
        : "Discounted Price should only be numeric."
      : "";
    validations.productDescription = productValueData.productDescription
      ? ""
      : "This field is required";
    validations.productCover =
      recordForEdit != null
        ? editCourseCover.length > 0
          ? ""
          : "Product cover is required"
        : productCover != null
        ? ""
        : "Product cover is required";
    validations.productMaterial =
      material.length > 0 || materialList.length > 0
        ? ""
        : "Atleast 1 material is required";
    setValidationsErrors({
      ...validations,
    });

    return Object.values(validations).every((x) => x == "");
  };

  const LinearProgressWithLabel = (props) => {
    return (
      <Mui.Box
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <Mui.Box sx={{ width: "100%", mr: 1 }}>
          <Mui.LinearProgress
            className={classes.uploadProgress}
            style={{ height: 20, borderRadius: 5 }}
            variant="determinate"
            {...props}
          />
        </Mui.Box>
        <Mui.Box sx={{ minWidth: "100%", marginTop: 5 }}>
          <Mui.Typography
            align="center"
            variant="body2"
          >{`${props.value}%`}</Mui.Typography>
        </Mui.Box>
      </Mui.Box>
    );
  };
  let uploadedFileIndex = 0;

  const uploadFile = (videoMaterial, newMaterials, files) => {
    let currentProgress = 0;
    setProgress(1);
    setUploadingName("Uploading files...");
    let percentages = [];
    let uploaded = 0;
    if (files.length > 0) {
      const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
      const REGION = process.env.REACT_APP_S3_REGION;
      ProductService.getKeysForValidatedUser(token).then((res) => {
        const KEY = res.data.key;
        const SECRET = res.data.secret;
        const awsCreds = {
          accessKeyId: KEY,
          secretAccessKey: SECRET,
        };
        files.map(async (singleFile, index) => {
          percentages.push(0);
          var file = singleFile.file;
          const target = {
            Bucket: S3_BUCKET,
            Key: singleFile.aws_name,
            Body: file,
          };
          console.log(singleFile);
          const parallelUploadS3 = new Upload({
            client: new S3Client({ region: REGION, credentials: awsCreds }),
            leavePartsOnError: false,
            params: target,
          });

          parallelUploadS3.on("httpUploadProgress", (evt) => {
            currentProgress = Math.round((evt.loaded / evt.total) * 100);
            percentages[index] = currentProgress;
            uploaded = 0;
            percentages.map((percentage) => {
              uploaded += percentage;
            });
            setProgress(
              Math.round((uploaded / (percentages.length * 100)) * 100)
            );
            if (
              Math.round((uploaded / (percentages.length * 100)) * 100) == 100
            ) {
              setUploadingName("Creating product...");
              handleCreateProduct(videoMaterial, newMaterials);
            }
          });
          await parallelUploadS3.done((res) => {
            console.log(res);
          });
        });
      });
    } else {
      setProgress(50);
      setUploadingName("Updating product...");
      setTimeout(() => {
        handleCreateProduct(videoMaterial, newMaterials);
      }, 2000);
    }
  };

  const handleUpdatePreview = (index, start, end) => {
    let updatedMaterial = editMaterialItems;
    console.log(index);
    updatedMaterial[index]["preview_starts"] = start;
    updatedMaterial[index]["preview_ends"] = end;
    props.setEditMaterialItems(updatedMaterial);
    setOpenPreviewModal(false);
  };

  const showValidationError = () =>
    toast.error("Fill all the required fields!");

  const handleProductSubmit = (e) => {
    e.preventDefault();
    const materialListFileLimit = materialList?.find(
      (item) => item?.fileSizeMB > 80
    );
    console.log(materialListFileLimit);
    if (materialListFileLimit) {
      toast.error("File size must be less than 80MB");
      return;
    }
    setInvalidNames([]);
    let videoMaterial = editMaterialItems.filter((item) => item.type == "mp4");
    let newMaterials = editMaterialItems.filter((item) => item.id == 0);
    uploadedFileIndex = 0;

    if (validateProductForm()) {
      setSubmitting(true);
      if (material.length > 0) {
        uploadFile(videoMaterial, newMaterials, material);
      } else {
        handleCreateProduct(videoMaterial, newMaterials);
      }
    } else {
      showValidationError();
    }
  };

  const handleCreateProduct = (videoMaterial, newMaterials) => {
    // addProductFields.watermarks = "SOME"

    if (productValueData.showWaterMark == "1" && freeProduct == "no") {
      productValueData.productAccessCode = generatePassCode(6);
    }
    const dataFile = new FormData();
    Object.entries(addProductFields).forEach(([key]) => {
      console.log(key, productValueData[key]);
      if (key == "expiryDate") {
        if (productExpires != "no") {
          dataFile.append(key, productValueData[key]);
        } else {
          dataFile.append(key, null);
        }
      } else {
        dataFile.append(key, productValueData[key]);
      }
    });
    let addedMaterial = [];
    console.log(material.length);
    console.log(material);
    for (let index = 0; index < material.length; index++) {
      console.log(newMaterials);
      let previews = {
        preview_starts: newMaterials[index]["preview_starts"],
        preview_ends: newMaterials[index]["preview_ends"],
      };

      dataFile.append(
        "productPreviews[" + index + "]",
        JSON.stringify(previews)
      );
      // remove .name to send file
      dataFile.append(
        "productMaterialImages[" + index + "]",
        JSON.stringify({
          name: material[index].name,
          aws_name: material[index].aws_name,
          type: material[index].type,
          size: material[index].fileSizeMB,
        })
      );
    }

    for (let index = 0; index < videoMaterial.length; index++) {
      dataFile.append(
        "productMaterialPreviews[" + index + "]",
        JSON.stringify(videoMaterial[index])
      );
    }

    // dataFile.append('productCover', productCover)
    if (newProductCover != null) {
      dataFile.append(
        "productCover",
        new File([productCover], "coverPhoto.jpeg", {
          type: "image/jpeg",
          lastModified: new Date(),
        })
      );
    }
    dataFile.append("teacherId", localStorage.getItem("user_id"));

    let selectedSubjectArray = subjects?.filter(
      (subject) => subject?.title == selectedProdCatVal
    );
    let selectedSubjectLevels = selectedSubjectArray[0]["levels"];
    selectedSubjectLevels = selectedSubjectLevels.map((level) => level.title);

    let actualLevels = selectedSubjectLevels.filter((level) =>
      selectedProdLevelVal.includes(level)
    );
    dataFile.append("productLevel", JSON.stringify(actualLevels));
    console.log(dataFile);

    if (addEditProduct) {
      dataFile.append("deletedMaterialId", deletedMaterialList);
      ProductService.submitProductEditForm(dataFile).then(
        (res) => {
          setProgress(100);
          setUploadingName("Product updated!");
          setLoaded(true);
          setTimeout(() => {
            handleProductListUpdate();
            handleAddProductPopupClose();
            resetForm();
            // setSubmitting(false)
            setNewProductCover(null);
            setProductCover(null);
            setEditCourseCover(null);
            props.setEditMaterialItems([]);
            props.updateProductList();
          }, 1000);
          console.log(res);
        },
        (error) => {
          setSubmitting(false);
          console.log(error);
        }
      );
    } else {
      ProductService.submitProductAddForm(dataFile).then(
        (res) => {
          handleProductListUpdate();
          handleAddProductPopupClose();
          setUploadingName("Product created!");
          resetForm();
          setLoaded(true);
          setTimeout(() => {
            setSubmitting(false);
            setNewProductCover(null);
            setProductCover(null);
            setEditCourseCover(null);
          }, 1500);
          props.setEditMaterialItems([]);
          props.updateProductList();
          console.log(res);
        },
        (error) => {
          console.log(error);
          setSubmitting(false);
        }
      );
    }
  };

  const handleImageChange = (event) => {
    setProductCover(event.target.files[0]);
  };

  let indexNew = 0;
  let materialNewFiles = [];
  const handleMaterialSelect = (event) => {
    if (event.target.files.length > 0) {
      let invalidNames = [];
      setInvalidNames([]);
      for (let index = 0; index < event.target.files.length; index++) {
        const file = event.target.files[index];

        const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
        if (event.target.files[index].name.indexOf("+") > -1) {
          invalidNames.push(event.target.files[index].name);
        } else {
          const materialNewData = {
            id: 0,
            org_name: event.target.files[index].name,
            material_name: event.target.files[index].material_name,
            path: URL.createObjectURL(event.target.files[index]),
            preview_starts: "00:00:00",
            preview_ends: "00:00:20",
            type: "mp4",
            local: true,
            indexNew: material.length,
            progress: 0,
            fileSizeMB,
          };
          // FOR AWS ONLY
          if (material.length > 0) {
            material.map((file) => materialNewFiles.push(file));
          }
          materialNewFiles.push({
            file: event.target.files[index],
            type: event.target.files[index].type,
            name: event.target.files[index].name,
            aws_name:
              new Date().getTime() + "-" + event.target.files[index].name,
            fileSizeMB,
          });
          // materialNewFiles = { file: event.target.files[index], type: event.target.files[index].type, name: event.target.files[index].name, aws_name: new Date().getTime() + '-' + event.target.files[index].name };
          // setMaterial([...material, materialNewFiles])
          console.log(material);
          if (recordForEdit == null) {
            setMaterialList((prev) => [...prev, materialNewData]);
          }

          // if (recordForEdit != null) {
          let newEditItems = props.editMaterialItems;
          newEditItems.push(materialNewData);
          props.setEditMaterialItems(newEditItems);
          // }
        }
      }
      // if (material.length > 0) {
      // setMaterial([...material, materialNewFiles])
      setMaterial(materialNewFiles);
      console.log(material);
      // } else {
      //     setMaterial(materialNewFiles)
      // }
      const materialListFileLimit = materialList?.find(
        (item) => item?.fileSizeMB > 80
      );
      if (materialListFileLimit) {
        toast.error("File size must be less than 80MB");
      }
      if (invalidNames.length > 0) {
        setInvalidNames(invalidNames);
      }
    }
  };

  const deleteFileHandler = (index, materialId, local, indexDelete) => {
    setDeletedMaterialList((deletedMaterialList) => [
      ...deletedMaterialList,
      materialId,
    ]);
    var actualList = materialList;
    actualList.splice(index, 1);
    console.log(actualList);
    setMaterialList(actualList);

    // var fileBuffer = [];
    // Array.prototype.push.apply(fileBuffer, material);

    // var newFiles = fileBuffer.filter(file => { return file != material[index] })
    // const obj = Object.assign({}, newFiles);
    // obj['length'] = newFiles.length
    // setMaterial(obj)
    var actualList = material;
    console.log(actualList, indexDelete);
    actualList.splice(indexDelete, 1);
    setMaterial(actualList);
  };

  const handleProGradeChange = (newValue, actionMeta) => {
    let itemValue = newValue === null ? "" : newValue.value;
    setProductValueData({
      ...productValueData,
      productGrad: itemValue,
    });

    setSelectedProdGradeVal({
      label: itemValue,
      value: itemValue,
    });
  };

  const handleProLevelChange = (event) => {
    let previous = selectedProdLevelVal;
    if (event.target.checked) {
      previous.push(event.target.value);
    } else {
      previous = previous.filter((item) => item != event.target.value);
    }
    console.log(previous);
    setSelectedProdLevelVal(previous);
    setProductValueData({
      ...productValueData,
      productLevel: JSON.stringify(selectedProdLevelVal),
    });
  };

  const handleProCategoryChange = (event) => {
    console.log(event.target.value);
    let itemValue = event.target.value === null ? "" : event.target.value;
    setProductValueData({
      ...productValueData,
      productCategory: itemValue,
    });
    let selectedSubject = subjects.filter(
      (subject) => subject.title == event.target.value
    );
    console.log(selectedSubject);
    setLevels(selectedSubject[0].levels);
    setSelectedProdCatVal(itemValue);
  };

  const handleProductExpiration = (value) => {
    console.log(value);
    setProductExpires(value ? "yes" : "no");
  };
  const handleWatereMark = (value) => {
    console.log(value);
    setAddWaterMark(value ? "1" : "0");
    setProductValueData({
      ...productValueData,
      showWaterMark: value == "1" ? 1 : 0,
    });
  };

  const handleFreeProduct = (value) => {
    console.log(value);
    setFreeProduct(value ? "yes" : "no");
    if (value) {
      setProductValueData({
        ...productValueData,
        productAccessCode: "FREECOURSE",
      });
    } else {
      setProductValueData({
        ...productValueData,
        productAccessCode: null,
      });
    }
  };

  const handleProTagChange = (newValue, actionMeta) => {
    const newTagCollection = [];
    const newTagNamesCollection = [];
    newValue.map((item) => {
      const newTagData = {
        label: item.value,
        value: item.value,
      };
      newTagCollection.push(newTagData);
      newTagNamesCollection.push(item.value);
    });
    setSelectedProdTagsVal(newTagCollection);

    setProductValueData({
      ...productValueData,
      productTags: newTagNamesCollection.toString(),
    });
  };

  const editPreviewHandler = (index, org_name, name, starts, ends, path) => {
    console.log(starts);
    setPreviewTitle(org_name);
    setPreviewName(name);
    setPreviewStartsAt(starts);
    setPreviewEndsAt(ends);
    if (path != null) setPreviewPath(path);
    // if (!starts)
    //     setPreviewStartsAt('00:00:00')
    // if (!ends)
    //     setPreviewEndsAt('00:00:20')

    setPreviewIndex(index);
    setOpenPreviewModal(true);

    // var time = new Date(duration * 1000).toISOString().substr(11, 8)
  };

  const handlePreviewModalClose = () => {
    setOpenPreviewModal(false);
    setPreviewStartsAt(null);
  };

  const fileIcons = {
    pdf: "/assets/icons/pdf.png",
    jpeg: "/assets/icons/image.png",
    png: "/assets/icons/image.png",
    jpg: "/assets/icons/image.png",
    doc: "/assets/icons/doc.png",
    docx: "/assets/icons/doc.png",
    mp4: "/assets/icons/video.png",
  };

  const handleRegeneratePassCode = () => {
    if (productValueData.price != null && productValueData.price != "") {
      alert("A product can either have an access code or a price.");
    } else {
      let newCode = generatePassCode(6);
      setProductValueData({
        ...productValueData,
        productAccessCode: newCode,
      });
      setPassCode(newCode);
    }
  };
  const handleRemovePassCode = () => {
    setProductValueData({
      ...productValueData,
      productAccessCode: "",
    });
    setPassCode("");
  };

  const handleNewImageUploaded = (newPicture) => {
    newPicture.url.then((res) => {
      console.log(res);
      setNewProductCover(res);
    });
    setProductCover(newPicture.blob);
    console.log(newPicture.blob);

    setShowProfileCropper(false);
  };

  const closeProfileCropper = () => {
    setShowProfileCropper(false);
  };

  const handleOpenImageCropper = () => {
    setShowProfileCropper(true);
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const CannotSale = () => {
    return (
      <>
        <h2 style={{ margin: 0, lineHeight: 1.5 }}>
          Teachers must have a verified account by{" "}
          <a
            style={{ color: "#4834d4", textDecoration: "none" }}
            target="_blank"
            href="https://stripe.com/"
          >
            Stripe
          </a>{" "}
          in order to create paid course.{" "}
          <Link
            style={{ color: "#4834d4", textDecoration: "none" }}
            to="/my-profile"
          >
            Verify Now
          </Link>
        </h2>
      </>
    );
  };

  return (
    <Grid item xs={12} className={classes.productAdd}>
      {validationsErrors && (
        <ToastContainer position="top-center" autoClose={4000} />
      )}
      {showProfileCropper && (
        <CoverCropper
          closeProfileCropper={closeProfileCropper}
          handleNewImageUploaded={handleNewImageUploaded}
          profile_picture={null}
        />
      )}
      {!validationsErrors && (
        <ToastContainer position="top-right" autoClose={2000} />
      )}
      {previewStartsAt != null && (
        <PreviewModal
          path={previewPath}
          index={previewIndex}
          handleUpdatePreview={handleUpdatePreview}
          starts={previewStartsAt}
          ends={previewEndsAt}
          openPreviewModal={openPreviewModal}
          handlePreviewModalClose={handlePreviewModalClose}
          previewTitle={previewTitle}
          previewName={previewName}
        />
      )}
      <Grid
        container
        align="center"
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <Grid item>
          <Mui.Typography
            variant="h5"
            align="center"
            className={classes.pageTitles}
          >
            {addEditProduct ? "Edit Product" : "Add New Product"}
          </Mui.Typography>
        </Grid>
        <Grid item>
          {addEditProduct && (
            <Link
              to={
                "/classes/" +
                productValueData.productName +
                "/" +
                productValueData.courseId
              }
              style={{ textDecoration: "none" }}
            >
              <Button
                size="small"
                style={{
                  color: "white",
                  textDecoration: "none",
                  backgroundColor: "#60974A",
                  marginTop: 10,
                }}
                className={classes.shareActionMobile}
              >
                <Mui.Icon
                  style={{ marginRight: 5 }}
                  className={classes.actionIcon}
                >
                  schedule
                </Mui.Icon>{" "}
                View class schedule
              </Button>
            </Link>
          )}
        </Grid>
      </Grid>
      {/* <form noValidate onSubmit={handleProductSubmit} encType='multipart/form-data'> */}
      <form noValidate encType="multipart/form-data">
        <input
          type="hidden"
          name="courseId"
          value={productValueData.courseId}
          onChange={handleInputChange}
        />
        <Grid
          container
          spacing={1}
          justifyContent="center"
          className={classes.itemContainer}
        >
          <Grid item xs={12} sm={4} className={classes.marginRight}>
            <p variant="body1" style={{ marginTop: 0, marginBottom: 10 }}>
              Product cover photo <small>(Recommended size, 275x215)</small>
              <span className={classes.redText}>*</span>
            </p>
            <div>
              {validationsErrors.productCover ? (
                <p
                  style={{ marginBottom: 10, marginLeft: 0 }}
                  className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
                >
                  {validationsErrors.productCover}
                </p>
              ) : (
                ""
              )}
              {/* <input accept="image/*" id="upload-profile-picture" onChange={handleImageChange} type='file' hidden error={"true"} /> */}
              <label
                htmlFor="upload-profile-picture"
                onClick={handleOpenImageCropper}
              >
                <Mui.Button
                  component="span"
                  className={
                    newProductCover != null || editCourseCover
                      ? "cover-photo-active"
                      : "cover-photo-section"
                  }
                >
                  {
                    // Conditions Updated by Waleed Umer
                    newProductCover != null ? (
                      // <img src={URL.createObjectURL(productCover)} width="100%" />
                      <img src={newProductCover} width="100%" />
                    ) : editCourseCover && editCourseCover.length > 0 ? (
                      <img
                        src={
                          process.env.REACT_APP_API_URL +
                          process.env.REACT_APP_TEACHER_COURSE_PATH +
                          "cover/" +
                          editCourseCover
                        }
                        width="100%"
                      />
                    ) : (
                      <div className={classes.coverWrapper}>
                        <Mui.Icon className={classes.pictureIcon}>
                          photo_camera
                        </Mui.Icon>
                        <Mui.Typography
                          variant="body2"
                          style={{ color: "#939393" }}
                        >
                          {" "}
                          Upload product cover
                        </Mui.Typography>
                      </div>
                    )
                  }
                </Mui.Button>
              </label>
            </div>
            <p variant="body1" style={{ marginTop: 0, marginBottom: 10 }}>
              Product Material <small>(Only PDF and mp4 are allowed)</small>{" "}
              <span className={classes.redText}>*</span>
            </p>
            <div
              variant="outlined"
              className={
                isMobile
                  ? classes.documentwrapperMobile
                  : classes.documentwrapper
              }
            >
              {/* Materials List */}
              <div className={classes.documentsScroller}>
                {materialList.length > 0 ? (
                  materialList.map((item, index) => {
                    return (
                      <>
                        <div
                          item
                          className={
                            item?.fileSizeMB > 80
                              ? "file-wrapper invalid-file"
                              : "file-wrapper"
                          }
                          style={{ marginLeft: 5, marginTop: 5 }}
                        >
                          <img
                            src={
                              fileIcons[
                                item.org_name.substr(
                                  item.org_name.lastIndexOf(".") + 1
                                )
                              ]
                            }
                          />
                          <div className="name-wrapper">
                            <div style={{ overflowWrap: "anywhere" }}>
                              <span>{item.org_name}</span>
                              <br></br>
                              {item.org_name.substr(
                                item.org_name.lastIndexOf(".") + 1
                              ) == "mp4" && <span>{item.preview_starts}</span>}
                            </div>
                            <div className="btns-wrapper">
                              {item.org_name.substr(
                                item.org_name.lastIndexOf(".") + 1
                              ) == "mp4" && (
                                <Mui.Tooltip
                                  title="Edit Preview"
                                  placement="top"
                                >
                                  <Mui.Icon
                                    onClick={() =>
                                      editPreviewHandler(
                                        index,
                                        item.org_name,
                                        item.material_name,
                                        item.preview_starts,
                                        item.preview_ends,
                                        item.path ? item.path : null
                                      )
                                    }
                                    className="edit-icon"
                                  >
                                    edit
                                  </Mui.Icon>
                                </Mui.Tooltip>
                              )}
                              {
                                <Mui.Tooltip
                                  title="Delete Material"
                                  placement="top"
                                >
                                  <Mui.Icon
                                    onClick={() =>
                                      deleteFileHandler(
                                        index,
                                        item.id,
                                        item.local,
                                        item.indexNew
                                      )
                                    }
                                    className="delete-icon"
                                  >
                                    close
                                  </Mui.Icon>
                                </Mui.Tooltip>
                              }
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <Mui.Typography
                    className={classes.noMaterialAttached}
                    variant="body1"
                  >
                    No material attached
                  </Mui.Typography>
                )}
              </div>

              {/* Materials List Ended */}
              <>
                <input
                  accept="video/*,.pdf,.doc,.docx"
                  id="upload-documents"
                  type="file"
                  hidden
                  multiple
                  onChange={handleMaterialSelect}
                />
                <label htmlFor="upload-documents" className="uploadButtonLabel">
                  <Mui.Button
                    component="span"
                    className={classes.btnAdjustment}
                    variant="outlined"
                  >
                    {/* <div className={classes.qualificationInner}> */}
                    <Mui.Icon className="upload-icon">backup</Mui.Icon>
                    <Mui.Typography
                      variant="body2"
                      style={{ textTransform: "none" }}
                    >
                      Add PDFs and/or MP4 Videos{" "}
                      <small>(each file max 80 mb) </small>
                    </Mui.Typography>
                    {/* </div> */}
                  </Mui.Button>
                </label>
              </>
            </div>
            {/* <div className={classes.fileUsageWrapper}>
              <FileUsage
                total={props.usage.total_usage}
                max={props.usage.allowed_usage}
              />
            </div> */}
            {invalid.length > 0 && (
              <div>
                <Alert severity="error">
                  <Typography variant="body1">
                    Following file(s) can't be uploaded because the name isn't
                    valid.
                  </Typography>
                  <Typography variant="body2">
                    Only " ! , _ , - , . , * , ' , (, ) " are allowed in file
                    name
                  </Typography>
                  <ul>
                    {invalid.map((name) => (
                      <li>{name}</li>
                    ))}
                  </ul>
                </Alert>
              </div>
            )}
            {validationsErrors.productMaterial ? (
              <p
                style={{ marginLeft: 0 }}
                className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
              >
                {validationsErrors.productMaterial}
              </p>
            ) : (
              ""
            )}
            {progresses > 0 && !isMobile && (
              <Mui.Box style={{ marginTop: 10 }} sx={{ width: "100%" }}>
                {/* PROGRESS BAR WITH PERCENTAGE COMMENTED OUT */}
                {/* <Mui.Typography align="center" style={{ marginBottom: 5 }} variant="body1">{uploadingName}</Mui.Typography>
                                <LinearProgressWithLabel value={progresses} /> */}
                <Box sx={{ width: "100%" }}>
                  <Mui.Typography
                    align="center"
                    style={{ marginBottom: 5 }}
                    variant="body1"
                  >
                    {"Uploading..."}
                  </Mui.Typography>
                  <LinearProgress
                    className={classes.LinearProgressClass}
                    variant="indeterminate"
                    style={{ height: 20, borderRadius: 10 }}
                  />
                </Box>
              </Mui.Box>
            )}
          </Grid>
          <Grid item xs={12} sm={7} className={classes.marginLeft}>
            <Grid container>
              <Grid item xs={12}>
                <p variant="body1" style={{ marginTop: 20, marginBottom: 10 }}>
                  Product name <span className={classes.redText}>*</span>
                </p>
                <TextField
                  variant="outlined"
                  placeholder="Product name"
                  name="productName"
                  value={productValueData.productName}
                  onChange={handleInputChange}
                  // {...(validationsErrors.productName && { helperText: validationsErrors.productName })}
                />
                {validationsErrors.productName && (
                  <FormHelperText style={{ marginLeft: 0 }}>
                    {validationsErrors.productName}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginBottom: 20 }}>
                <p variant="body1" style={{ marginTop: 20, marginBottom: 10 }}>
                  Product Description <span className={classes.redText}>*</span>
                </p>
                <CKEditor
                  config={{
                    toolbar: ["bold", "italic", "heading"],
                  }}
                  editor={ClassicEditor}
                  content={check}
                  onReady={(editor) => {
                    if (
                      productValueData.productDescription != null &&
                      productValueData.productDescription != ""
                    )
                      editor.setData(productValueData.productDescription);
                  }}
                  onChange={(event, editor) => {
                    setProductValueData({
                      ...productValueData,
                      productDescription: editor.getData(),
                    });
                  }}
                  {...(validationsErrors.productDescription && {
                    helperText: validationsErrors.productDescription,
                  })}
                />
                {validationsErrors.productDescription && (
                  <FormHelperText style={{ marginLeft: 0 }}>
                    {validationsErrors.productDescription}
                  </FormHelperText>
                )}
              </div>
              {/* <TextField
                                label="Product Description"
                                variant="outlined"
                                placeholder="Product Description"
                                name='productDescription'
                                value={productValueData.productDescription}
                                onChange={handleInputChange}
                                rows={4}
                                multiline

                                {...(validationsErrors.productDescription && { helperText: validationsErrors.productDescription })}
                            /> */}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <p variant="body1" style={{ marginTop: 0, marginBottom: 10 }}>
                  Subject <span className={classes.redText}>*</span>
                </p>
                <FormControl variant="outlined">
                  <Select
                    name="productCategory"
                    placeholder="Subject"
                    onChange={handleProCategoryChange}
                    value={selectedProdCatVal}
                  >
                    {subjects != null &&
                      subjects.map((subject) => {
                        return (
                          <MenuItem value={subject.title}>
                            {subject.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {validationsErrors.productCategory && (
                    <FormHelperText style={{ marginLeft: 0 }}>
                      {validationsErrors.productCategory}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* <Grid container style={{ marginBottom: 25 }}> */}
              <Grid
                item
                xs={12}
                sm={4}
                className="switch-wrapper"
                style={{ paddingLeft: 20 }}
              >
                <p>Product will expire</p>
                <Switch
                  onChange={handleProductExpiration}
                  checked={productExpires == "yes" ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                {productExpires == "yes" && (
                  <>
                    <p>Expiration date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        variant="outlined"
                        type="date"
                        className="date-picker"
                        value={productValueData.expiryDate}
                        onChange={(e) =>
                          setProductValueData({
                            ...productValueData,
                            expiryDate: e.$d,
                          })
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        renderInput={(params) => (
                          <TextField
                            value={productValueData.expiryDate}
                            name="expiryDate"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4} className="switch-wrapper">
                <div style={{ display: "flex", gap: "10px" }}>
                  <p>Add Watermark</p>
                  <Tooltip
                    placement="right-end"
                    title="Print function will only be allowed when ‘Add Watermark’ toggle is on."
                  >
                    <Icon
                      style={{
                        cursor: "pointer",
                        fontSize: "14px",
                        marginTop: "3px",
                      }} // Change cursor to pointer for better UX
                    >
                      info
                    </Icon>
                  </Tooltip>
                </div>
                <Switch
                  onChange={handleWatereMark}
                  checked={addWaterMark == "1" ? true : false}
                />
              </Grid>

              {addWaterMark == "1" && (
                <>
                  <Grid item xs={12} sm={6}>
                    <p
                      variant="body1"
                      style={{ marginTop: 0, marginBottom: 10 }}
                    >
                      Add Watermark <span className={classes.redText}>*</span>
                    </p>

                    {availableWaterMark.map((watermark, index) => (
                      <TextField
                        key={watermark.id}
                        value={watermark.value}
                        variant="outlined"
                        placeholder="Add Watermark"
                        name={`watermark_${index}`}
                        style={{ width: 400 }}
                        disabled={false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Mui.Tooltip
                                title={
                                  <h2 style={{ margin: 5 }}>Access Code</h2>
                                }
                                placement="top"
                              >
                                <span
                                  style={{
                                    padding: "0 10px",
                                    background: "#f0f0f0",
                                    borderRadius: "4px",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    pointerEvents: "none", // Disable click events
                                  }}
                                >
                                  {watermark.watermarkCode}{" "}
                                  {/* Display the access code */}
                                </span>
                              </Mui.Tooltip>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {recordForEdit != null && (
                                <>
                                  <Mui.Tooltip
                                    title={
                                      <h2 style={{ margin: 5 }}>
                                        Copy course URL
                                      </h2>
                                    }
                                    placement="top"
                                  >
                                    <Icon
                                      onClick={handleCopyLink}
                                      className="refresh-passcode-icon red-icon group-icon"
                                    >
                                      link
                                    </Icon>
                                  </Mui.Tooltip>
                                  <Mui.Tooltip
                                    title={
                                      <h2 style={{ margin: 5 }}>
                                        Copy access code
                                      </h2>
                                    }
                                    placement="top"
                                  >
                                    <Icon
                                      onClick={() =>
                                        handleCopyCodeForWaterMark(
                                          watermark?.watermarkCode
                                        )
                                      }
                                      className="refresh-passcode-icon green-icon group-icon"
                                    >
                                      key
                                    </Icon>
                                  </Mui.Tooltip>
                                  <Mui.Tooltip
                                    title={
                                      <h2 style={{ margin: 5 }}>
                                        Copy access code and URL
                                      </h2>
                                    }
                                    placement="top"
                                  >
                                    <Icon
                                      onClick={() =>
                                        handleCopyBothForWaterMark(
                                          watermark?.watermarkCode
                                        )
                                      }
                                      className="refresh-passcode-icon blue-icon group-icon"
                                    >
                                      content_copy
                                    </Icon>
                                  </Mui.Tooltip>
                                </>
                              )}
                              <Mui.Tooltip
                                title={<h2 style={{ margin: 5 }}>Remove</h2>}
                                placement="top"
                              >
                                <Icon
                                  onClick={() =>
                                    handleRemveWaterMark(watermark)
                                  }
                                  className="refresh-passcode-icon blue-icon group-icon"
                                >
                                  clear
                                </Icon>
                              </Mui.Tooltip>
                            </InputAdornment>
                          ),
                          inputProps: {
                            style: { paddingLeft: "10px" }, // Add left padding here
                          },
                        }}
                        onChange={(e) =>
                          handleInputChangeForWaterMark(index, e)
                        }
                      />
                    ))}
                    <Button
                      style={{ marginBottom: "10px" }}
                      type="button"
                      variant="outlined"
                      onClick={handleAddInput}
                    >
                      +
                    </Button>
                  </Grid>
                </>
              )}
              {/* </Grid> */}
              <Grid item xs={12}>
                <p variant="body1" style={{ marginTop: 0, marginBottom: 10 }}>
                  Product Level <small>(visible to customers)</small>
                  <span className={classes.redText}>*</span>
                </p>
                {levels != null ? (
                  levels.map((level) => {
                    return (
                      <FormControlLabel
                        label={level.title}
                        control={
                          <Checkbox
                            checked={
                              selectedProdLevelVal.indexOf(level.title) > -1
                            }
                            onChange={handleProLevelChange}
                            value={level.title}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        variant="outlined"
                      ></FormControlLabel>
                      // <FormControlLabel label={level.title} control={<Checkbox
                      //     checked={recordForEdit != null ? (selectedProdCatVal == recordForEdit.productCategory && selectedProdLevelVal.indexOf(level.title) > -1) : (selectedProdLevelVal.indexOf(level.title) > -1)}
                      //     onChange={handleProLevelChange}
                      //     value={level.title}
                      //     inputProps={{ 'aria-label': 'controlled' }}
                      // />} variant="outlined">

                      // </FormControlLabel>
                    );
                  })
                ) : (
                  <p style={{ color: "gray", fontStyle: "italic" }}>
                    Select subject to see levels
                  </p>
                )}
                {/* <CreatableSelect
                                        name="productLevel"
                                        placeholder="Product Level"
                                        onChange={handleProLevelChange}
                                        options={addProductLevels}
                                        value={selectedProdLevelVal}
                                        className={classes.creatableSelect}
                                        // components={{
                                        //     ValueContainer: CustomValueContainer
                                        // }}
                                        styles={{
                                            valueContainer: (provided, state) => ({
                                                ...provided,
                                                overflow: "visible"
                                            }),
                                            placeholder: (provided, state) => ({
                                                ...provided,
                                                position: "absolute",
                                                transform: state.hasValue || state.selectProps.inputValue ? "translate(-14px, -28px) scale(0.75)" : "",
                                                backgroundColor: state.hasValue || state.selectProps.inputValue ? 'white' : '',
                                                paddingLeft: state.hasValue || state.selectProps.inputValue ? '5px' : '',
                                                paddingRight: state.hasValue || state.selectProps.inputValue ? '5px' : ''
                                            })
                                        }}
                                    /> */}
                {validationsErrors.productLevel && (
                  <FormHelperText style={{ marginLeft: 0 }}>
                    {validationsErrors.productLevel}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Grid container>
                  <Grid item xs={12} sm={2} className="switch-wrapper">
                    <p>Free product</p>
                    <Switch
                      onChange={handleFreeProduct}
                      checked={freeProduct == "yes" ? true : false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {freeProduct == "no" && (
                <>
                  {addWaterMark !== "1" && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <p
                          variant="body1"
                          style={{ marginTop: 0, marginBottom: 10 }}
                        >
                          Product Price{" "}
                          <span className={classes.redText}>*</span>
                        </p>
                        {!canSale ? (
                          <HtmlTooltip
                            interactive
                            style={{ backgroundColor: "white" }}
                            title={<CannotSale />}
                            placement="top"
                          >
                            <TextField
                              variant="outlined"
                              placeholder="Product Price"
                              name="price"
                              disabled={true}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    HK$
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </HtmlTooltip>
                        ) : (
                          <TextField
                            variant="outlined"
                            placeholder="Product Price"
                            name="price"
                            disabled={!canSale}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  HK$
                                </InputAdornment>
                              ),
                            }}
                            value={productValueData.price}
                            onChange={(e) => {
                              if (passCode == null || passCode == "") {
                                handleInputChange(e);
                              } else {
                                alert(
                                  "A product can either have a price or an access code."
                                );
                              }
                            }}
                          />
                        )}
                        {validationsErrors.price && (
                          <FormHelperText style={{ marginLeft: 0 }}>
                            {validationsErrors.price}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <p
                          variant="body1"
                          style={{ marginTop: 0, marginBottom: 10 }}
                        >
                          Product access code{" "}
                          <span className={classes.redText}>*</span>
                        </p>
                        <TextField
                          variant="outlined"
                          placeholder="Product access code"
                          name={"productAccessCode"}
                          disabled={true}
                          value={productValueData.productAccessCode}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Mui.Tooltip
                                  title={
                                    <h2 style={{ margin: 5 }}>
                                      Generate passcode
                                    </h2>
                                  }
                                  placement="top"
                                >
                                  <Icon
                                    onClick={handleRegeneratePassCode}
                                    className="refresh-passcode-icon gray-icon group-icon"
                                  >
                                    refresh
                                  </Icon>
                                </Mui.Tooltip>
                                {recordForEdit != null && (
                                  <>
                                    <Mui.Tooltip
                                      title={
                                        <h2 style={{ margin: 5 }}>
                                          Copy course URL
                                        </h2>
                                      }
                                      placement="top"
                                    >
                                      <Icon
                                        onClick={handleCopyLink}
                                        className="refresh-passcode-icon red-icon group-icon"
                                      >
                                        link
                                      </Icon>
                                    </Mui.Tooltip>
                                    <Mui.Tooltip
                                      title={
                                        <h2 style={{ margin: 5 }}>
                                          Copy access code
                                        </h2>
                                      }
                                      placement="top"
                                    >
                                      <Icon
                                        onClick={handleCopyCode}
                                        className="refresh-passcode-icon green-icon group-icon"
                                      >
                                        key
                                      </Icon>
                                    </Mui.Tooltip>
                                    <Mui.Tooltip
                                      title={
                                        <h2 style={{ margin: 5 }}>
                                          Copy access code and URL
                                        </h2>
                                      }
                                      placement="top"
                                    >
                                      <Icon
                                        onClick={handleCopyBoth}
                                        className="refresh-passcode-icon blue-icon group-icon"
                                      >
                                        content_copy
                                      </Icon>
                                    </Mui.Tooltip>
                                  </>
                                )}
                                <Mui.Tooltip
                                  title={
                                    <h2 style={{ margin: 5 }}>Remove access</h2>
                                  }
                                  placement="top"
                                >
                                  <Icon
                                    onClick={handleRemovePassCode}
                                    className="refresh-passcode-icon red-icon group-icon"
                                  >
                                    close
                                  </Icon>
                                </Mui.Tooltip>
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleInputChange}
                          // {...(validationsErrors.productAccessCode && { helperText: validationsErrors.productAccessCode })}
                        />
                        {validationsErrors.productAccessCode && (
                          <FormHelperText style={{ marginLeft: 0 }}>
                            {validationsErrors.productAccessCode}
                          </FormHelperText>
                        )}
                      </Grid>
                    </>
                  )}
                </>
              )}
              <Grid item xs={6}>
                <p variant="body1" style={{ marginTop: 0, marginBottom: 10 }}>
                  Product Grade <small>(not visible to customers)</small>
                  <span className={classes.redText}>*</span>
                </p>
                <FormControl variant="outlined">
                  <CreatableSelect
                    name="productGrad"
                    onChange={handleProGradeChange}
                    options={addProductGrad}
                    value={selectedProdGradeVal}
                    className={classes.creatableSelect}
                    placeholder="Product Grade"
                    // components={{
                    //     ValueContainer: CustomValueContainer
                    // }}
                    styles={{
                      valueContainer: (provided, state) => ({
                        ...provided,
                        overflow: "visible",
                      }),
                      placeholder: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        transform:
                          state.hasValue || state.selectProps.inputValue
                            ? "translate(-14px, -28px) scale(0.75)"
                            : "",
                        backgroundColor:
                          state.hasValue || state.selectProps.inputValue
                            ? "white"
                            : "",
                        paddingLeft:
                          state.hasValue || state.selectProps.inputValue
                            ? "5px"
                            : "",
                        paddingRight:
                          state.hasValue || state.selectProps.inputValue
                            ? "5px"
                            : "",
                      }),
                    }}
                  />
                  {validationsErrors.productGrad && (
                    <FormHelperText style={{ marginLeft: 0 }}>
                      {validationsErrors.productGrad}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <p variant="body1" style={{ marginTop: 0, marginBottom: 10 }}>
                  Worksheet Preview Mode{" "}
                  <span className={classes.redText}>*</span>
                </p>
                <FormControl
                  label="Worksheet Preview Mode"
                  variant="outlined"
                  className={classes.selectPlaeholder}
                >
                  {/* <InputLabel>Worksheet Preview Mode</InputLabel> */}
                  <Select
                    labelId="worksheetPreviewModeLabel"
                    name="worksheetPreviewMode"
                    displayEmpty
                    value={productValueData.worksheetPreviewMode}
                    onChange={handleInputChange}
                  >
                    <MenuItem disabled value="">
                      <em>Worksheet Preview Mode</em>
                    </MenuItem>
                    {addProductWorksheetMode.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {validationsErrors.worksheetPreviewMode && (
                    <FormHelperText style={{ marginLeft: 0 }}>
                      {validationsErrors.worksheetPreviewMode}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <p variant="body1" style={{ marginTop: 0, marginBottom: 10 }}>
                  Product Tags <span className={classes.redText}>*</span>
                </p>
                <FormControl variant="outlined">
                  <CreatableSelect
                    isMulti
                    name="productTags"
                    onChange={handleProTagChange}
                    options={addProductTags}
                    value={selectedProdTagsVal}
                    className={classes.creatableSelect}
                    placeholder="Product Tags"
                    autoCapitalize
                    // components={{
                    //     ValueContainer: CustomValueContainer
                    // }}
                    styles={{
                      valueContainer: (provided, state) => ({
                        ...provided,
                        overflow: "visible",
                      }),
                      placeholder: (provided, state) => ({
                        ...provided,
                        position: "absolute",
                        transform:
                          state.hasValue || state.selectProps.inputValue
                            ? "translate(-14px, -28px) scale(0.75)"
                            : "",
                        backgroundColor:
                          state.hasValue || state.selectProps.inputValue
                            ? "white"
                            : "",
                        paddingLeft:
                          state.hasValue || state.selectProps.inputValue
                            ? "5px"
                            : "",
                        paddingRight:
                          state.hasValue || state.selectProps.inputValue
                            ? "5px"
                            : "",
                      }),
                    }}
                  />
                  {validationsErrors.productTags && (
                    <FormHelperText style={{ marginLeft: 0 }}>
                      {validationsErrors.productTags}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid style={{ width: "100%" }}>
                {progresses > 0 && isMobile && (
                  <Mui.Box style={{ marginTop: 0 }} sx={{ width: "100%" }}>
                    {/* PROGRESS BAR WITH PERCENTAGE COMMENTED OUT */}
                    {/* <Mui.Typography align="center" style={{ marginBottom: 5 }} variant="body1">{uploadingName}</Mui.Typography>
                                        <LinearProgressWithLabel value={progresses} /> */}
                    <Box sx={{ width: "100%" }}>
                      <Mui.Typography
                        align="center"
                        style={{ marginBottom: 5 }}
                        variant="body1"
                      >
                        {"Uploading..."}
                      </Mui.Typography>
                      <LinearProgress
                        className={classes.LinearProgressClass}
                        variant="indeterminate"
                        style={{ height: 20, borderRadius: 10 }}
                      />
                    </Box>
                  </Mui.Box>
                )}
              </Grid>
              <Grid item xs={12} className={classes.actionButtonItems}>
                {/* <ToggleButtonGroup
                                value={productExpirationDate.value}
                                name="productExpirationDate"
                                onChange={(e, value) => productExpirationDate.handleExpirationChange(e, value)} 
                            >
                                <ToggleButton value="0">Indefinite</ToggleButton>
                                <ToggleButton value="1">Days</ToggleButton>                                
                            </ToggleButtonGroup> */}
                {/* <Grid container justifyContent="flex-end"> */}
                {/* <Grid item xs={12} sm={3} className={classes.btnWidth}> */}
                {
                  // submitting &&
                  // <>
                  //     {
                  //         loaded == false &&
                  //         <PostLoader loaded={false} title="Creating course, please wait..." />
                  //     }
                  //     {
                  //         loaded == true &&
                  //         <PostLoader loaded={true} title="Creating course, please wait..." />
                  //     }
                  // </>
                }
                <Button
                  variant="outlined"
                  disabled={submitting}
                  type="button"
                  onClick={handleProductSubmit}
                  className={classes.buttonSpacing}
                >
                  {submitting ? "Please wait.." : "Submit"}
                </Button>
                {/* </Grid> */}
                {/* </Grid> */}
              </Grid>

              <Mui.Icon
                className={classes.closeIcon}
                variant="outlined"
                size="large"
                onClick={() => {
                  handleAddProductPopupClose();
                  resetForm();
                  setEditCourseCover(null);
                  setProductCover(null);
                }}
              >
                close
              </Mui.Icon>
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={4} className={classes.errorFieldPadding}></Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}
