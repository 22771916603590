import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Popover,
  Typography,
  Box,
  LinearProgress,
} from "@material-ui/core";
import StorageRoundedIcon from "@material-ui/icons/StorageRounded";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
  },
  progress: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
    alignItems: "center",
    gap: "5px",
  },
  popover: {
    borderRadius: 8,
  },
}));

const FileUsage = ({ total, max, variant = "" }) => {
  const totalUsed = parseFloat(total).toFixed(2);
  const classes = useStyles();
  const maxUsage = max; // in MB
  const remaining = maxUsage - totalUsed;
  const usagePercentage = ((totalUsed - remaining) / maxUsage) * 100;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "file-usage-popover" : undefined;

  const FileUsageContent = () => (
    <Box
      className={classes.root}
      border={1}
      borderRadius={8}
      borderColor="grey.300"
    >
      <Box className={classes.header}>
        <StorageRoundedIcon className={classes.icon} color="primary" />
        <Typography variant="p">File Usage</Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={Math.min(usagePercentage, 100)}
        className={classes.progress}
      />
      <Box className={classes.details}>
        <Typography variant="body2" color="textSecondary">
          Used: {totalUsed} MB
        </Typography>
        <Typography variant="body2" color="textSecondary">
          |
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Remaining: {remaining > 0 ? remaining : 0} MB
        </Typography>
        <Typography variant="body2" color="textSecondary">
          |
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Max: {maxUsage} MB
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      {variant === "popover" ? (
        <div>
          <Button
            color="primary"
            onClick={handleClick}
            className={classes.button}
            size="small"
          >
            <StorageRoundedIcon className={classes.icon} />
            <Typography variant="p">Show File Usage</Typography>
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            classes={{ paper: classes.popover }}
          >
            <FileUsageContent />
          </Popover>
        </div>
      ) : (
        <FileUsageContent />
      )}
    </>
  );
};

export default FileUsage;
