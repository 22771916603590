import React, { useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import "../css/teacherReg.css";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ButtonBase from "@material-ui/core/ButtonBase";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Forgotpassword from "./Forgotpassword";
import * as Mui from "@material-ui/core";
import * as Mul from "@material-ui/lab";
// import Link from '@material-ui/core/Link';
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Image from "material-ui-image";
import Auth from "../../services/auth";
import AdminService from "../../services/admin";
import { UserContext } from "../../context/UserContext";
import { BuyNowContext } from "../../context/BuyNowContext";
import { AuthContext } from "../../context/AuthContext";

import { FavouriteContext } from "../../context/FavouriteContext";
import jwt_decode from "jwt-decode";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import SocialAuth from "./SocialAuth";
import ProductService from "../../services/products";
import roles from "../../routes/roles";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '80vh',
    borderRadius: 15,
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  image: {
    backgroundImage: "url(/assets/rightHero.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#0d333a",
    backgroundSize: "60%",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none",
      backgroundColor: "transparent",
    },
  },
  paper: {
    margin: theme.spacing(2, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      margin: 10,
    },
  },
  papersignup: {
    margin: theme.spacing(2, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      margin: 10,
    },
  },
  closeIcon: {
    position: "absolute",
    fontSize: 30,
    color: "#cc041f",
    right: 5,
    top: 5,
  },
  avatar: {
    width: "30%",
    marginBottom: 15,
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  avatarRegister: {
    width: "20%",
    marginBottom: 15,
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(0),
  },
  formReg: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  fbBtn: {
    width: "99%",
    backgroundColor: "transparent",
    border: "none",
    height: 40,
    backgroundColor: "#3963c7",
    borderRadius: 5,
  },
  gBtn: {
    width: "99%",
    backgroundColor: "transparent",
    border: "none",
    height: 40,
    backgroundColor: "#E8E8E8",
    borderRadius: 5,
  },
  fbBtnReg: {
    width: "99%",
    backgroundColor: "transparent",
    border: "none",
    height: 40,
    backgroundColor: "#3963c7",
    borderRadius: 5,
  },
  gBtnReg: {
    width: "99%",
    backgroundColor: "transparent",
    border: "none",
    height: 40,
    backgroundColor: "#E8E8E8",
    borderRadius: 5,
  },
  socialImg: {
    width: "100%",
  },
  socialAuthWrapper: {
    width: "100%",
    marginBottom: 20,
    backgroundColor: "transparent",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  socialAuth: {
    backgroundColor: "transparent",
    border: "none",
    width: "48%",
  },
  socialAuthReg: {
    backgroundColor: "transparent",
    border: "none",
    width: "32%",
  },
  errorText: {
    color: "red",
  },
  mobileHeading: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      fontSize: 20,
    },
  },
  loginSection: {
    borderRadius: 10,
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  dontHave: {
    textAlign: "center",
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  dontHaveBtn: {
    fontSize: 14,
    marginLeft: 10,
    color: "#3f51b5",
  },
  textField: {
    marginBottom: 0,
  },
  regFields: {
    margin: 0,
  },
  waitOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffffc9",
    zIndex: "10",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
  },
  signing: {
    color: "white",
  },
  waitTxt: {
    color: "black",
  },
  loader: {
    marginBottom: 0,
    width: "20%",
  },
  resentEmailText: {
    color: "#3f51b5",
    cursor: "pointer",
  },
}));

export default function SignInSide(props) {
  const ref = useRef();
  const classes = useStyles();
  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState(null);
  const [isSpamSignup, setIsSpamSignUp] = React.useState(null);
  const [fullname, setFullname] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [newemail, setNewEmail] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const { jwt } = React.useContext(UserContext);
  const [token, setToken] = jwt;
  const { user } = React.useContext(UserContext);
  const [currentUser, setCurrentUser] = user;
  const { notification } = React.useContext(UserContext);
  const [teacherNotification, setTeacherNotification] = notification;
  const { role } = React.useContext(UserContext);
  const [userRole, setUserRole] = role;
  const { favouriteItem } = React.useContext(FavouriteContext);
  const [favouriteItems, setFavouriteItems] = favouriteItem;
  const { auth } = React.useContext(BuyNowContext);
  const [isLoggedIn, setIsLoggedIn] = auth;
  const [form, setForm] = React.useState("signin");
  const [registrationError, setRegErrors] = React.useState([]);
  const [loginError, setLoginErrors] = React.useState({});
  const [loginMessage, setLoginMessage] = React.useState(null);
  const [registering, setRegistering] = React.useState(false);
  const [open, setOpen] = React.useContext(AuthContext);
  const [showAlert, setShowAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [notVerified, setNotVerified] = React.useState(false);
  const [notVerifiedUser, setNotVerifiedUser] = React.useState(null);
  const [sendingVerification, setSendingVerification] = React.useState(false);

  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [registeredUser, setRegisteredUser] = React.useState(null);

  const handleLogin = () => {
    setLoading(true);
    setNotVerified(false);
    let user = {
      username: username,
      password: password,
    };
    Auth.loginUser(username, password).then((response) => {
      if (response.data.status == 200 && !response.data.error) {
        let decodedToken = jwt_decode(response.data.data.token);
        ProductService.getFavourites(decodedToken.user_id).then((fvrres) => {
          Auth.getUserById(decodedToken.user_id).then((res) => {
            setOpen(false);
            setFavouriteItems(fvrres.data);
            setCurrentUser(res.data);
            console.log(res.data);
            setToken(response.data.data.token);
            setUserRole(roles[decodedToken.auth_type]);
            if (decodedToken.auth_type == 4)
              setNotifications(decodedToken.auth_id);

            localStorage.setItem("user_id", decodedToken.user_id);
            localStorage.setItem("subscriber_id", decodedToken.subscriber_id);
            localStorage.setItem("auth_id", decodedToken.auth_id);
            localStorage.setItem("jwt_token", response.data.data.token);
            console.log(decodedToken);
            setLoading(false);
          });
        });
      } else {
        setLoading(false);
        if (typeof response.data.message === "object") {
          setLoginErrors(response.data.message);
        } else {
          setLoginMessage(response.data.messages);
          setNotVerified(response.data.status == 405);
          setNotVerifiedUser(response.data.user);
        }
      }
    });
  };

  const handleForgotPassword = (show) => {
    setForgotPassword(show);
  };

  const handleLoginKey = (e) => {
    if (e.keyCode == 13) {
      setNotVerified(false);
      setLoading(true);
      setLoginMessage(null);
      let user = {
        username: username,
        password: password,
      };
      Auth.loginUser(username, password).then((response) => {
        if (response.data.status == 200 && !response.data.error) {
          let decodedToken = jwt_decode(response.data.data.token);
          ProductService.getFavourites(decodedToken.user_id).then((fvrres) => {
            Auth.getUserById(decodedToken.user_id).then((res) => {
              setOpen(false);
              setFavouriteItems(fvrres.data);
              setCurrentUser(res.data);
              setToken(response.data.data.token);
              localStorage.setItem("user_id", decodedToken.user_id);
              localStorage.setItem("subscriber_id", decodedToken.subscriber_id);
              localStorage.setItem("auth_id", decodedToken.auth_id);
              localStorage.setItem("jwt_token", response.data.data.token);
              setUserRole(roles[decodedToken.auth_type]);
              if (decodedToken.auth_type == 4)
                setNotifications(decodedToken.auth_id);
              setLoading(false);
            });
          });
        } else {
          setLoading(false);
          if (typeof response.data.message === "object") {
            setLoginErrors(response.data.message);
          } else {
            setLoginMessage(response.data.messages);
            setNotVerified(response.data.status == 405);
            setNotVerifiedUser(response.data.user);
          }
        }
      });
    }
  };

  const setNotifications = (id) => {
    AdminService.getTeacherDocumentRequest(id).then((res) => {
      console.log(res);
      if (res.data.length > 0) {
        setTeacherNotification(true);
      } else {
        setTeacherNotification(false);
      }
    });
  };

  const handleRegistration = () => {
    if (isSpamSignup?.length > 0) {
      setRegErrors(["spam"]);
      console.log(registrationError);
      return;
    }
    setLoading(true);
    setRegErrors([]);
    setRegistering(true);
    let user = {
      username: username,
      password: password,
      confirmpassword: confirmPassword,
      first_name: firstName,
      last_name: lastName,
      email: newemail,
      subscriber_id: 0,
    };
    Auth.register(user).then((response) => {
      setRegistering(false);
      console.log(response);
      if (response.data.status == 200) {
        setLoading(false);
        // setOpen(false)
        setRegisteredUser(response.data);
        setSuccess(true);
        // Auth.loginUser(user.username, user.password).then((response) => {
        //     if (response.data.status == 200 && !response.data.error) {
        //         // console.log(response.data.data)
        //         // setCurrentUser(response.data.data.user)
        //         // setToken(response.data.data.token)
        //         // let decodedToken = jwt_decode(response.data.data.token)
        //         // localStorage.setItem('user_id', decodedToken.user_id)
        //         // localStorage.setItem('subscriber_id', decodedToken.subscriber_id)
        //         // localStorage.setItem('auth_id', decodedToken.auth_id)
        //         // localStorage.setItem('jwt_token', response.data.data.token)
        //         // setLoading(false)
        //         // // setOpen(false)
        //         // setSuccess(true)
        //     }
        // })
      } else {
        setLoading(false);
        if (response.data.message) setRegErrors(response.data.message);
      }
    });
  };

  const handleRegistrationKey = (e) => {
    if (e.keyCode == 13) {
      if (isSpamSignup?.length > 0) {
        setRegErrors(["spam"]);
        return;
      }
      setLoading(true);
      setRegErrors([]);
      setRegistering(true);
      let user = {
        username: username,
        password: password,
        confirmpassword: confirmPassword,
        first_name: firstName,
        last_name: lastName,
        email: newemail,
        subscriber_id: 0,
      };
      Auth.register(user).then((response) => {
        setRegistering(false);
        console.log(response);
        if (response.data.status == 200) {
          setLoading(false);
          setSuccess(true);
          setRegisteredUser(response.data);
          // Auth.loginUser(user.username, user.password).then((response) => {
          //     if (response.data.status == 200 && !response.data.error) {
          //         // setToken(response.data.data.token)
          //         // setCurrentUser(response.data.data.user)
          //         // let decodedToken = jwt_decode(response.data.data.token)
          //         // localStorage.setItem('user_id', decodedToken.user_id)
          //         // localStorage.setItem('subscriber_id', decodedToken.subscriber_id)
          //         // localStorage.setItem('auth_id', decodedToken.auth_id)
          //         // localStorage.setItem('jwt_token', response.data.data.token)
          //         // setLoading(false)
          //         // // setOpen(false)
          //         // setSuccess(true)
          //     }
          // })
        } else {
          setLoading(false);
          if (response.data.message) setRegErrors(response.data.message);
        }
      });
    }
  };

  React.useEffect(() => {
    if (form == "register") {
      setRegErrors([]);
    }
  }, [form]);

  const changeAuth = (auth) => {
    setForm(auth);
  };

  const handleSocialLogin = (user) => {
    console.log(user);
    setLoading(true);
    var social = {
      access_token: user._token.accessToken,
      email: user._profile.email,
      provider: "facebook",
      user_id: user._profile.id,
      first_name: user._profile.firstName,
      last_name: user._profile.lastName,
      display_name: user._profile.name,
      profile_picture: user._profile.profilePicURL,
    };
    Auth.socialAuth(social).then((response) => {
      console.log(response);
      if (response.data.status == 200 && !response.data.error) {
        setToken(response.data.data.token);
        console.log(response.data.data);
        setCurrentUser(response.data.data.user);
        let decodedToken = jwt_decode(response.data.data.token);
        localStorage.setItem("user_id", decodedToken.user_id);
        localStorage.setItem("subscriber_id", decodedToken.subscriber_id);
        localStorage.setItem("auth_id", decodedToken.auth_id);
        localStorage.setItem("jwt_token", response.data.data.token);
        setOpen(false);
        setLoading(false);
      }
    });
  };
  const handleGoogleLogin = (user, err) => {
    console.log(user);
    setLoading(true);
    var social = {
      access_token: user._token.accessToken,
      email: user._profile.email,
      provider: "gmail",
      first_name: user._profile.firstName,
      last_name: user._profile.lastName,
      display_name: user._profile.name,
      profile_picture: user._profile.profilePicURL,
    };
    Auth.socialAuth(social).then((response) => {
      if (response.data.status == 200 && !response.data.error) {
        setToken(response.data.data.token);
        let decodedToken = jwt_decode(response.data.data.token);
        setCurrentUser(response.data.data.user);
        localStorage.setItem("user_id", decodedToken.user_id);
        localStorage.setItem("subscriber_id", decodedToken.subscriber_id);
        localStorage.setItem("auth_id", decodedToken.auth_id);
        localStorage.setItem("jwt_token", response.data.data.token);
        setOpen(false);
        setLoading(false);
      }
    });
  };
  const handleSocialLoginFailure = (err) => {
    console.log(err);
  };

  const handleResendEmail = () => {
    // console.log(teacherId)
    Auth.resendVerificationEmail(registeredUser.data.user_id).then((res) => {
      // console.log(res)
      // Here
      setShowAlert(true);
    });
  };

  const handleResendVerificationEmail = () => {
    // console.log(teacherId)
    setSendingVerification(true);
    Auth.resendVerificationEmail(notVerifiedUser).then((res) => {
      // console.log(res)
      // Here
      setNotVerified(false);
      setSendingVerification(false);
      setLoginMessage(null);
      setShowAlert(true);
    });
  };

  const AlertMessage = (props) => {
    return (
      <Mui.Snackbar
        open={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Mul.Alert
          severity={props.type}
          sx={{ width: "100%" }}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          {props.message}
        </Mul.Alert>
      </Mui.Snackbar>
    );
  };

  return (
    <>
      <AlertMessage message="Email sent!" type="success" />
      <Grid
        container
        component="main"
        justifyContent="center"
        className={classes.root}
      >
        {forgotPassword ? (
          <Grid item xs={12} sm={5}>
            <Forgotpassword handleForgotPassword={handleForgotPassword} />
          </Grid>
        ) : (
          <>
            {success ? (
              <Grid item xs="11" sm="5">
                <div className="success-card">
                  <div className="check-wrapper">
                    <Icon className="check-icon">check</Icon>
                  </div>
                  <Typography className="text-dark" variant="h6">
                    Thank You for registering with us.
                  </Typography>
                  <Typography className="text-dark" variant="body1">
                    We have sent a verification link to
                  </Typography>
                  <Typography className="text-dark" variant="h6">
                    "{newemail}"
                  </Typography>
                  <Typography className="text-dark" variant="body2">
                    Open your mail app and verify your email.
                  </Typography>
                  <Button className="ok-btn">
                    <Link
                      onClick={() => setOpen(false)}
                      style={{ textDecoration: "none", color: "white" }}
                      to={"/"}
                    >
                      OK
                    </Link>
                  </Button>
                  <Typography style={{ marginTop: 20 }} variant="body2">
                    Didn't receive confirmation email?
                  </Typography>
                  <Typography variant="body2">
                    <span
                      onClick={handleResendEmail}
                      style={{
                        textDecoration: "none",
                        color: "#3c87e7",
                        cursor: "pointer",
                      }}
                    >
                      Resend the email
                    </span>
                  </Typography>
                  {showAlert == true && (
                    <Typography variant="body2">
                      <span style={{ textDecoration: "none", color: "green" }}>
                        Email sent!
                      </span>
                    </Typography>
                  )}
                </div>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={10}
                md={form === "signin" ? 5 : 8}
                className={classes.loginSection}
                component={Paper}
                elevation={6}
                square
              >
                {loading && (
                  <Grid item xs={12} className={classes.waitOverlay}>
                    <img
                      src="/assets/ringloader.gif"
                      className={classes.loader}
                    />
                    <Typography variant="h6" className={classes.waitTxt}>
                      Please wait...
                    </Typography>
                  </Grid>
                )}
                <ButtonBase
                  onClick={props.handleClose}
                  className={classes.closeIcon}
                >
                  <CancelOutlinedIcon
                    style={{ fontSize: "1.2rem" }}
                  ></CancelOutlinedIcon>
                </ButtonBase>

                {form === "signin" ? (
                  <div className={classes.paper}>
                    <img className={classes.avatar} src="/assets/Logo.png" />
                    <Typography
                      style={{ marginBottom: 0, fontWeight: 300 }}
                      component="h2"
                      variant="h6"
                    >
                      Sign in
                    </Typography>
                    <Typography
                      className={classes.errorText}
                      style={{ marginBottom: 20 }}
                      variant="body1"
                    >
                      {loginMessage}
                    </Typography>
                    {notVerified && (
                      <Typography
                        onClick={handleResendVerificationEmail}
                        className={classes.resentEmailText}
                        style={{ marginBottom: 20 }}
                        variant="body2"
                      >
                        {sendingVerification
                          ? "Sending verification email..."
                          : "Resend Verification Email?"}
                      </Typography>
                    )}
                    <form className={classes.form} noValidate>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        required
                        onChange={(e) => {
                          setUsername(e.target.value);
                          delete loginError.username;
                          setLoginErrors(loginError);
                        }}
                        error={loginError.hasOwnProperty("username")}
                        id="email"
                        label="Username or email"
                        name="username"
                        autoFocus
                      />
                      {loginError.hasOwnProperty("username") && (
                        <small style={{ color: "red" }}>
                          {"Username cannot be empty"}
                        </small>
                      )}

                      <TextField
                        className={classes.textField}
                        fullWidth
                        onKeyDown={(e) => handleLoginKey(e)}
                        variant="outlined"
                        margin="normal"
                        required
                        onChange={(e) => {
                          setPassword(e.target.value);
                          delete loginError.password;
                          setLoginErrors(loginError);
                        }}
                        error={loginError.hasOwnProperty("password")}
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                      />
                      {loginError.hasOwnProperty("password") && (
                        <small style={{ color: "red" }}>
                          {"Password cannot be empty"}
                        </small>
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLogin}
                        className={classes.submit}
                      >
                        Sign In
                      </Button>
                      <Grid container direction="column">
                        <Grid item xs>
                          <span
                            onClick={() => handleForgotPassword(true)}
                            style={{
                              textDecoration: "none",
                              fontSize: 14,
                              color: "#3F51B5",
                              cursor: "pointer",
                            }}
                          >
                            Forgot password?
                          </span>
                        </Grid>
                        <Grid item xs className={classes.dontHave}>
                          <span>{"Don't have an account?"}</span>
                          <ButtonBase
                            className={classes.dontHaveBtn}
                            onClick={() => changeAuth("register")}
                          >
                            {"Sign Up"}
                          </ButtonBase>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                ) : (
                  <div className={classes.papersignup}>
                    <img
                      className={classes.avatarRegister}
                      src="/assets/Logo.png"
                    />
                    <Typography
                      style={{ marginBottom: 0, fontWeight: 300 }}
                      component="h2"
                      variant="h6"
                    >
                      Register
                    </Typography>

                    <form className={classes.formReg} noValidate>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            className={classes.regFields}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            error={
                              registrationError["first_name"] &&
                              registrationError["first_name"]
                            }
                            onChange={(e) => setFirstName(e.target.value)}
                            helperText={
                              registrationError["first_name"] &&
                              registrationError["first_name"]
                            }
                            id="firstName"
                            label="First Name"
                            name="firstName"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            className={classes.regFields}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            error={
                              registrationError["last_name"] &&
                              registrationError["last_name"]
                            }
                            onChange={(e) => setLastName(e.target.value)}
                            helperText={
                              registrationError["last_name"] &&
                              registrationError["last_name"]
                            }
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            className={classes.regFields}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            error={
                              registrationError["email"] &&
                              registrationError["email"]
                            }
                            onChange={(e) => setNewEmail(e.target.value)}
                            helperText={
                              registrationError["email"] &&
                              registrationError["email"]
                            }
                            id="email"
                            label="Email Address"
                            name="email"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            className={classes.regFields}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            error={registrationError["username"]}
                            onChange={(e) => setUsername(e.target.value)}
                            id="username"
                            label="Username or email"
                            name="username"
                            helperText={registrationError["username"]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            className={classes.regFields}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            error={registrationError["password"]}
                            onChange={(e) => setPassword(e.target.value)}
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            helperText={registrationError["password"]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            className={classes.regFields}
                            fullWidth
                            onKeyDown={(e) => handleRegistrationKey(e)}
                            variant="outlined"
                            margin="normal"
                            required
                            error={registrationError["confirmpassword"]}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            name="confirmpassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmpassword"
                            helperText={registrationError["confirmpassword"]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ display: "none" }}>
                          <TextField
                            className={classes.regFields}
                            fullWidth
                            onKeyDown={(e) => handleRegistrationKey(e)}
                            variant="outlined"
                            margin="normal"
                            error={registrationError["Spam detected!"]}
                            onChange={(e) => setIsSpamSignUp(e.target.value)}
                            name="isSpamSignup"
                            label="isSpamSignup"
                            type="text"
                            id="isSpamSignup"
                          />
                        </Grid>
                        {isSpamSignup?.length > 0 && (
                          <Grid item xs={12} sm={12} style={{ color: "red" }}>
                            Spam detected!
                          </Grid>
                        )}
                      </Grid>

                      {registering ? (
                        <Button
                          variant="contained"
                          disabled
                          color="primary"
                          onClick={handleRegistration}
                          className={classes.submit}
                        >
                          Please wait...
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleRegistration}
                          className={classes.submit}
                        >
                          Register
                        </Button>
                      )}

                      <Grid container>
                        <Grid
                          item
                          xs
                          style={{ textAlign: "center", marginTop: 10 }}
                        >
                          <span>{"Don't have an account?"}</span>
                          <ButtonBase
                            className={classes.dontHaveBtn}
                            onClick={() => changeAuth("signin")}
                          >
                            {"Sign In"}
                          </ButtonBase>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                )}
                <h4
                  className={classes.mobileHeading}
                  style={{
                    fontWeight: 300,
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Or
                </h4>
                <div className={classes.socialAuthWrapper}>
                  <SocialAuth
                    provider="google"
                    appId="327155937090-kktv63fqt3o5imsof5mupqipqdt2cups.apps.googleusercontent.com"
                    onLoginSuccess={handleGoogleLogin}
                    autoLogin={false}
                    onLoginFailure={handleSocialLoginFailure}
                    className={
                      form === "signin"
                        ? classes.socialAuth
                        : classes.socialAuthReg
                    }
                  >
                    <ButtonBase
                      style={{ width: "100%" }}
                      className={
                        form === "signin" ? classes.gBtn : classes.gBtnReg
                      }
                    >
                      <img
                        className={classes.socialImg}
                        src="/assets/login-with-g.png"
                      />
                    </ButtonBase>
                  </SocialAuth>
                  <SocialAuth
                    provider="facebook"
                    // appId="2493286747365617"
                    appId="565617981409692" //edhub
                    autoLogin={false}
                    onLoginSuccess={handleSocialLogin}
                    onLoginFailure={handleSocialLoginFailure}
                    className={
                      form === "signin"
                        ? classes.socialAuth
                        : classes.socialAuthReg
                    }
                  >
                    <ButtonBase
                      style={{ width: "100%" }}
                      className={
                        form === "signin" ? classes.fbBtn : classes.fbBtnReg
                      }
                    >
                      <img
                        className={classes.socialImg}
                        src="/assets/login-with-fbB.png"
                      />
                    </ButtonBase>
                  </SocialAuth>
                </div>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
