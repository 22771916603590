import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ProductService from "../services/products";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import {
  Dialog,
  DialogContent,
  Snackbar,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AddProduct from "../components/teacher/AddProduct";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
// Added By Waleed Umer
import * as Mui from "@material-ui/core";
import * as Mul from "@material-ui/lab";
import SearchBar from "../components/teacher/productSearch";
import Slide from "@material-ui/core/Slide";
import SingleProduct from "../components/products/singleProduct";
import ShareProduct from "../components/teacher/modals/ShareProduct";
import RingLoader from "../components/shared/loaders/ringLoader";
import QRCode from "react-qr-code";

import Box from "@material-ui/core/Box";
import Popper from "@material-ui/core/Popper";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import { Redirect, useHistory } from "react-router-dom";
import "./css/static.css";
import FileUsage from "../components/products/fileUsage";

const useStyles = makeStyles((theme) => ({
  cartWrapper: {
    backgroundColor: "white",
    marginTop: 50,
    marginBottom: 50,
    padding: 20,
    borderRadius: 15,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
      padding: 0,
      backgroundColor: "transparent",
    },
  },
  noPaddingMobile: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  itemImageWrapper: {
    paddingRight: 25,
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      height: "auto",
    },
  },
  itemImageHeader: {
    paddingRight: 40,
    height: 35,
  },
  itemImage: {
    width: "100%",
    height: "100%",
    borderRadius: 5,
    border: "solid 1px #b4b4b4",
  },
  itemContainer: {
    paddingTop: 10,
    paddingBottom: 0,
    marginBottom: 30,
    minHeight: "60vh",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      flexDirection: "row",
    },
  },

  singleItem: {
    marginBottom: 10,
    backgroundColor: "#f4f4f4",
    borderRadius: 10,
    width: "100%",
    padding: 5,
  },
  singleItemBg: {
    backgroundColor: "white",
    position: "relative",
  },
  headerItem: {
    marginBottom: 10,
    backgroundColor: "#e2edef",
    borderRadius: 10,
    width: "100%",
    padding: 5,
  },
  courseName: {
    // fontSize: 18,
    lineHeight: 1.3,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  courseNameTitle: {
    paddingLeft: 5,
    paddingRight: 5,
    marginBottom: 15,
    height: "4.6em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
  },
  courseNameMobile: {
    backgroundColor: "white",
    padding: 5,
    borderRadius: 20,
    fontSize: 12,
  },
  courseAccessCode: {
    fontSize: 12,
    lineHeight: 1.3,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  headerName: {
    // fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.3,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  searchLink: {
    textDecoration: "none",
    color: "black",
  },
  tag: {
    backgroundColor: "#dadada",
    marginRight: 2,
    marginBottom: 2,
    fontSize: 9,
  },
  pageTitles: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 5,
    },
  },
  noProductWrapper: {
    height: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  addProductPopupDiv: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "100%",
    },
  },
  containerCustomHieght: {
    // height: '50%'
    flexDirection: "row",
    display: "flex",
  },
  iconSpacing: {
    marginRight: theme.spacing(2),
  },
  navAction: {
    minWidth: 10,
    height: 25,
    width: 25,
    borderRadius: 30,
    backgroundColor: "#1a95ac",
    margin: 3,
    "&:hover": {
      backgroundColor: "#24646F",
    },
  },
  viewAction: {
    minWidth: 10,
    height: 25,
    width: 25,
    borderRadius: 30,
    backgroundColor: "#225bc2",
    margin: 3,
    "&:hover": {
      backgroundColor: "#24646F",
    },
  },
  editAction: {
    minWidth: 10,
    height: 25,
    width: 25,
    borderRadius: 30,
    backgroundColor: "#f6ac24",
    margin: 3,
    "&:hover": {
      backgroundColor: "#24646F",
    },
  },
  deleteAction: {
    minWidth: 10,
    height: 25,
    width: 25,
    borderRadius: 30,
    backgroundColor: "#d3342c",
    margin: 3,
    "&:hover": {
      backgroundColor: "#24646F",
    },
  },
  shareAction: {
    minWidth: 10,
    height: 25,
    width: 25,
    borderRadius: 30,
    backgroundColor: "#01b594",
    margin: 3,
    "&:hover": {
      backgroundColor: "#00967b",
    },
  },
  viewActionMobile: {
    minWidth: 10,
    height: 25,
    width: 25,
    borderRadius: 30,
    backgroundColor: "#225bc2",
    margin: 0,
    "&:hover": {
      backgroundColor: "#24646F",
    },
  },
  editActionMobile: {
    minWidth: 10,
    height: 25,
    width: 25,
    borderRadius: 30,
    backgroundColor: "#f6ac24",
    margin: 0,
    "&:hover": {
      backgroundColor: "#24646F",
    },
  },
  deleteActionMobile: {
    minWidth: 10,
    height: 25,
    width: 25,
    borderRadius: 30,
    backgroundColor: "#d3342c",
    margin: 0,
    "&:hover": {
      backgroundColor: "#24646F",
    },
  },
  shareActionMobile: {
    minWidth: 10,
    height: 25,
    width: 25,
    borderRadius: 30,
    backgroundColor: "#01b594",
    margin: 0,
    "&:hover": {
      backgroundColor: "#00967b",
    },
  },
  actionIcon: {
    fontSize: 16,
  },
  sortIcon: {
    backgroundColor: "#cddcdf",
    borderRadius: 20,
    marginLeft: 10,
    fontSize: 14,
    padding: 3,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#a6b3b6",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  creationBtn: {
    backgroundColor: "#60974a",
    borderRadius: 20,
    color: "white",
    textTransform: "capitalize",
    marginLeft: 10,
    fontSize: 14,
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "#4f803c",
    },
  },
  pagination: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "rgb(26 149 172)",
      color: "white",
    },
  },
  iconsView: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  icon: {
    color: "gray",
    marginRight: 6,
    cursor: "pointer",
  },
  active: {
    color: "#1A95AC",
    marginRight: 6,
    cursor: "pointer",
  },
  mobilePriceWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    padding: 5,
  },
}));

export default function MyProducts({ location }) {
  const productSuccessMessage = "Product added successfully.";
  const productUpdateMessage = "Product updated successfully.";
  const productDeleteMessage = "Product deleted successfully.";
  const classes = useStyles();
  const [items, setItems] = React.useState([]);
  const [inactiveItems, setInactiveItems] = React.useState([]);
  const [deletedItems, setDeletedItems] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState(null);
  const [usage, setUsage] = React.useState({
    total_usage: 0,
    allowed_usage: 0,
  });
  const [activeTab, setActiveTab] = React.useState("active");
  const [editMaterialItems, setEditMaterialItems] = React.useState([]);
  const [editCourseCover, setEditCourseCover] = React.useState([]);
  const [recordForEdit, setRecordForEdit] = React.useState(null);

  const { addNew } = useParams();
  const [showAdd, setShowAdd] = React.useState(addNew);
  const [productPage, setProductPage] = React.useState(1);
  const [viewStyle, setViewStyle] = React.useState("list");
  let history = useHistory();
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(10);
  const [sortBy, setSortBy] = React.useState("course_name");
  const [productLink, setProductLink] = React.useState(null);
  const [productAccessCode, setProductAccessCode] = React.useState(null);
  const [productID, setProductID] = React.useState(null);
  const [shareProductPopUp, setShareProductPopUp] = React.useState(false);

  const [sortOrder, setSortOrder] = React.useState("ASC");
  const [addProductPopup, setAddProductPopup] = React.useState(false);
  const [addEditProduct, setAddEditProduct] = React.useState(false);
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [deletePopupId, setDeletePopupId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [productAddSuccess, setProductAddSuccess] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState(
    productSuccessMessage
  );
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [sorterOpen, setSorterOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  React.useEffect(() => {
    if (showAdd === "new" ? true : false) {
      setAddProductPopup(true);
    } else {
      getProducts(1, null);
    }
    // setAddProductPopup(showAdd === 'new' ? true : false)
  }, []);

  const handleToggle = () => {
    setSorterOpen((sorterOpen) => !sorterOpen);
  };

  const setMemoryUsage = (data) => {
    const { total_usage, allowed_usage } = data || 0;
    setUsage({
      total_usage,
      allowed_usage,
    });
  };

  const handleProductListUpdate = () => {
    ProductService.getProductsByTeacherId(
      localStorage.getItem("user_id"),
      1,
      null
    ).then((res) => {
      setItems(res.data);
      setMemoryUsage(res.data);
      if (recordForEdit != null) {
        setSnackbarMessage(productUpdateMessage);
      } else {
        setSnackbarMessage(productSuccessMessage);
      }
      setProductAddSuccess(true);
      setTimeout(() => {
        setProductAddSuccess(false);
      }, 5000);
    });
  };

  const handleAddProductPopupClose = () => {
    // getProducts(1, null)
    setAddProductPopup(false);
    setAddEditProduct(false);
  };

  const handleDeletePopupClose = () => {
    setDeletePopup(false);
  };

  const handleDeletePopupConfirmation = () => {
    ProductService.deleteProductByTeacher(deletePopupId).then((res) => {
      var statuses = {
        inactive: 0,
        active: 1,
        deleted: -1,
      };
      getProducts(statuses[activeTab], null);
      setDeletePopup(false);
      setSnackbarMessage(productDeleteMessage);
      setProductAddSuccess(true);
      setTimeout(() => {
        setProductAddSuccess(false);
      }, 5000);
    });
  };

  const handleViewChnage = (style) => {
    setViewStyle(style);
  };

  const deleteIconClick = (e, itemId) => {
    e.preventDefault();
    setDeletePopupId(itemId);
    setDeletePopup(true);
  };

  const handleAddProductBtnClick = () => {
    setAddProductPopup(true);
    setRecordForEdit(null);
  };

  const shareProduct = (e, link, accessCode, courseId) => {
    setProductLink(link);
    setProductAccessCode(accessCode);
    setShareProductPopUp(true);
    setProductID(courseId);
  };

  const editIconClick = (e, itemId, link, code) => {
    e.preventDefault();
    const newTagNamesCollection = [];
    itemId["tags"].split(",").map((itemdata, index) => {
      const newTagData = {
        label: itemdata,
        value: itemdata,
      };
      newTagNamesCollection.push(newTagData);
    });

    const updateList = {
      courseId: itemId["course_id"],
      productName: itemId["course_name"],
      productAccessCode: itemId["access_code"],
      expiryDate: itemId["course_end_date"],
      productTags: JSON.stringify(newTagNamesCollection),
      productCategory: itemId["course_category"],
      productGrad: itemId["course_grade_level"],
      productLevel: itemId["course_level"],
      price: itemId["course_tution_fee"],
      discountedPrice: itemId["discounted_price"],
      worksheetPreviewMode: itemId["pdf_preview_mode"],
      productDescription: itemId["course_description"],
      watermarks: itemId["watermarks"],
      showWaterMark: itemId["showWaterMark"],
    };

    const matList = [];
    itemId["material"].map((item) => {
      console.log(item);
      matList.push({
        org_name: item["org_name"],
        material_name: item["material_name"],
        preview_starts: item["preview_starts"],
        preview_ends: item["preview_ends"],
        type: item["type"],
      });
    });

    setProductLink(link);
    setProductAccessCode(code);
    setProductID(itemId["course_id"]);
    setEditMaterialItems(itemId["material"]);
    setEditCourseCover(itemId["course_cover"]);
    setRecordForEdit(updateList);
    setAddEditProduct(true);
    setAddProductPopup(true);
  };

  // Code Added by Waleed Umer -----------------------------------------------
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleClose = () => {
    setAddProductPopup(false);
  };

  const handleUpdateMaterial = (materials) => {
    setEditMaterialItems(materials);
  };

  const ProductPopup = () => {
    return (
      <Dialog
        disableEscapeKeyDown={true}
        fullScreen
        open={addProductPopup}
        onClose={handleAddProductPopupClose}
        className={classes.addProductPopupDiv}
        TransitionComponent={Transition}
      >
        <DialogContent>
          <AddProduct
            productID={productID}
            url={productLink}
            accessCode={productAccessCode}
            setEditMaterialItems={handleUpdateMaterial}
            handleMaterialItems={handleMaterialItems}
            updateProductList={updateProductList}
            setEditCourseCover={setEditCourseCover}
            editCourseCover={editCourseCover}
            editMaterialItems={editMaterialItems}
            recordForEdit={recordForEdit}
            addEditProduct={addEditProduct}
            handleProductListUpdate={handleProductListUpdate}
            handleAddProductPopupClose={handleAddProductPopupClose}
            addProductPopup={addProductPopup}
            usage={usage}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const handleMaterialItems = (index, material) => {
    console(material);
  };

  const SmallProduct = ({ item, index, courseCover, courseLevels }) => {
    return (
      <Grid xs={6} key={index} item className={classes.singleItem}>
        <Grid
          className={classes.singleItemBg}
          container
          flexdirection="row"
          alignItems="center"
        >
          <Grid item xs={12} sm={1} className={classes.itemImageWrapper}>
            <img src={courseCover} className={classes.itemImage} />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid
              container
              flexdirection="row"
              justifyContent="space-between"
              className={classes.containerCustomHieght}
            >
              <Grid item xs={12} sm={4}>
                <Typography className={classes.courseNameTitle} variant="body1">
                  {item.course_name}
                </Typography>
              </Grid>
              <Grid item className={classes.mobilePriceWrapper} xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography
                      className={classes.courseNameMobile}
                      variant="body2"
                    >
                      {item.course_category}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={classes.courseNameMobile}
                      variant="body2"
                    >
                      ${item.course_tution_fee} HK
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                                <Typography className={classes.courseName} variant="body2">${item.discounted_price} HK</Typography>
                                <Typography className={classes.courseAccessCode} variant="body2">{item.access_code}</Typography>
                            </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Grid
              container
              flexdirection="column"
              justifyContent="space-between"
              style={{ padding: 5 }}
              alignItems="center"
            >
              <Link
                target="_blank"
                className={classes.searchLink}
                onClick={() => {
                  localStorage.setItem("productId", item.course_id);
                  localStorage.setItem("productImage", courseCover);
                }}
                to={{
                  pathname:
                    "/product/" +
                    (item.course_tution_fee == null ||
                    item.course_tution_fee == ""
                      ? "view"
                      : "preview") +
                    "/" +
                    item.course_name +
                    "/" +
                    item.course_id,
                  state: {
                    image: courseCover,
                    id: item.course_id,
                  },
                }}
              >
                <Mui.Tooltip placement="top" title="View">
                  <Button
                    style={{ color: "white", textDecoration: "none" }}
                    className={classes.viewActionMobile}
                  >
                    <Icon className={classes.actionIcon}>visibility</Icon>
                  </Button>
                </Mui.Tooltip>
              </Link>
              <Mui.Tooltip placement="top" title="Edit">
                <Button
                  style={{ color: "white", textDecoration: "none" }}
                  className={classes.editActionMobile}
                >
                  <EditIcon
                    className={classes.actionIcon}
                    onClick={(e) =>
                      editIconClick(
                        e,
                        item,
                        "product/preview/" +
                          item.course_name +
                          "/" +
                          item.course_id,
                        item.access_code
                      )
                    }
                  />
                </Button>
              </Mui.Tooltip>
              <Mui.Tooltip placement="top" title="Delete">
                <Button
                  style={{ color: "white", textDecoration: "none" }}
                  className={classes.deleteActionMobile}
                >
                  <DeleteOutline
                    className={classes.actionIcon}
                    onClick={(e) => deleteIconClick(e, item.course_id)}
                  />
                </Button>
              </Mui.Tooltip>
              <Mui.Tooltip placement="top" title="Share this course">
                <Button
                  style={{ color: "white", textDecoration: "none" }}
                  className={classes.shareActionMobile}
                >
                  <Mui.Icon
                    className={classes.actionIcon}
                    onClick={(e) =>
                      shareProduct(
                        e,
                        "product/preview/" +
                          item.course_name +
                          "/" +
                          item.course_id,
                        item.access_code,
                        item.course_id
                      )
                    }
                  >
                    share
                  </Mui.Icon>
                </Button>
              </Mui.Tooltip>
              <Mui.Tooltip placement="top" title="View class schedule">
                <Link
                  to={"/classes/" + item.course_name + "/" + item.course_id}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{
                      color: "white",
                      textDecoration: "none",
                      backgroundColor: "#60974A",
                    }}
                    className={classes.shareActionMobile}
                  >
                    <Mui.Icon className={classes.actionIcon}>schedule</Mui.Icon>
                  </Button>
                </Link>
              </Mui.Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const MediumProduct = ({ item, index, courseCover, courseLevels }) => {
    return (
      <Grid key={index} item className={classes.singleItem}>
        <Grid
          className={classes.singleItemBg}
          container
          flexdirection="row"
          alignItems="center"
        >
          <Grid item xs={12} sm={2} className={classes.itemImageWrapper}>
            <img src={courseCover} className={classes.itemImage} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Grid
              container
              flexdirection="row"
              justifyContent="space-between"
              className={classes.containerCustomHieght}
            >
              <Grid item xs={12} md={12} style={{ paddingLeft: 10 }}>
                <Typography className={classes.courseNameTitle} variant="body1">
                  {item.course_name}
                </Typography>
                <Typography
                  className={classes.courseNameMobile}
                  variant="body2"
                >
                  ${item.course_tution_fee} HK
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid
              container
              flexdirection="column"
              justifyContent="space-between"
              style={{ padding: 5 }}
              alignItems="center"
            >
              <Link
                target="_blank"
                className={classes.searchLink}
                onClick={() => {
                  localStorage.setItem("productId", item.course_id);
                  localStorage.setItem("productImage", courseCover);
                }}
                to={{
                  pathname:
                    "/product/" +
                    (item.course_tution_fee == null ||
                    item.course_tution_fee == ""
                      ? "view"
                      : "preview") +
                    "/" +
                    item.course_name +
                    "/" +
                    item.course_id,
                  state: {
                    image: courseCover,
                    id: item.course_id,
                  },
                }}
              >
                <Mui.Tooltip placement="top" title="View">
                  <Button
                    style={{ color: "white", textDecoration: "none" }}
                    className={classes.viewActionMobile}
                  >
                    <Icon className={classes.actionIcon}>visibility</Icon>
                  </Button>
                </Mui.Tooltip>
              </Link>
              <Mui.Tooltip placement="top" title="Edit">
                <Button
                  style={{ color: "white", textDecoration: "none" }}
                  className={classes.editActionMobile}
                >
                  <EditIcon
                    className={classes.actionIcon}
                    onClick={(e) =>
                      editIconClick(
                        e,
                        item,
                        "product/preview/" +
                          item.course_name +
                          "/" +
                          item.course_id,
                        item.access_code
                      )
                    }
                  />
                </Button>
              </Mui.Tooltip>
              <Mui.Tooltip placement="top" title="Delete">
                <Button
                  style={{ color: "white", textDecoration: "none" }}
                  className={classes.deleteActionMobile}
                >
                  <DeleteOutline
                    className={classes.actionIcon}
                    onClick={(e) => deleteIconClick(e, item.course_id)}
                  />
                </Button>
              </Mui.Tooltip>
              <Mui.Tooltip placement="top" title="Share this course">
                <Button
                  style={{ color: "white", textDecoration: "none" }}
                  className={classes.shareActionMobile}
                >
                  <Mui.Icon
                    className={classes.actionIcon}
                    onClick={(e) =>
                      shareProduct(
                        e,
                        "product/preview/" +
                          item.course_name +
                          "/" +
                          item.course_id,
                        item.access_code,
                        item.course_id
                      )
                    }
                  >
                    share
                  </Mui.Icon>
                </Button>
              </Mui.Tooltip>
              <Mui.Tooltip placement="top" title="View class schedule">
                <Link
                  to={"/classes/" + item.course_name + "/" + item.course_id}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    style={{
                      color: "white",
                      textDecoration: "none",
                      backgroundColor: "#60974A",
                    }}
                    className={classes.shareActionMobile}
                  >
                    <Mui.Icon className={classes.actionIcon}>schedule</Mui.Icon>
                  </Button>
                </Link>
              </Mui.Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Products = () => {
    return (
      <>
        {loading && <RingLoader title="Searching..." />}
        {viewStyle == "grid" ? (
          <Grid container style={{ marginTop: 20 }} alignItems="flex-start">
            {items.map((item, index) => {
              return (
                <SingleProduct
                  from={"teacher-shop"}
                  product={item}
                  key={index}
                  index={index}
                />
              );
            })}
          </Grid>
        ) : (
          <Grid
            container
            className={classes.itemContainer}
            alignItems="flex-start"
          >
            <Hidden smDown>
              <Grid item className={classes.headerItem}>
                <Grid container flexdirection="row" alignItems="center">
                  <Grid
                    item
                    xs={12}
                    sm={1}
                    className={classes.itemImageHeader}
                  ></Grid>
                  <Grid item xs={12} sm={9}>
                    <Grid
                      container
                      flexdirection="row"
                      justifyContent="space-between"
                      className={classes.containerCustomHieght}
                    >
                      <Grid item xs={4} className={classes.header}>
                        <Typography
                          className={classes.headerName}
                          variant="body1"
                        >
                          Name
                        </Typography>
                        <Icon
                          onClick={() => handleSorting("course_name")}
                          className={classes.sortIcon}
                        >
                          unfold_more
                        </Icon>
                      </Grid>
                      <Grid item xs={2} className={classes.header}>
                        <Typography
                          className={classes.headerName}
                          variant="body1"
                        >
                          Category
                        </Typography>
                        <Icon
                          onClick={() => handleSorting("course_category")}
                          className={classes.sortIcon}
                        >
                          unfold_more
                        </Icon>
                      </Grid>
                      <Grid item xs={2} className={classes.header}>
                        <Typography
                          className={classes.headerName}
                          variant="body1"
                        >
                          Price
                        </Typography>
                        <Icon
                          onClick={() => handleSorting("course_tution_fee")}
                          className={classes.sortIcon}
                        >
                          unfold_more
                        </Icon>
                      </Grid>
                      <Grid item xs={2} className={classes.header}>
                        <Typography
                          className={classes.headerName}
                          variant="body1"
                        >
                          Access Code
                        </Typography>
                        {/* <Icon onClick={() => handleSorting('access_code')} className={classes.sortIcon}>unfold_more</Icon> */}
                      </Grid>
                      {/* <Grid item xs={2} className={classes.header}>
                                                <Typography className={classes.headerName} variant="body1">Level</Typography>
                                                <Icon onClick={() => handleSorting('course_level')} className={classes.sortIcon}>unfold_more</Icon>
                                            </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography
                      style={{ textAlign: "center" }}
                      className={classes.headerName}
                      variant="body1"
                    >
                      Action
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            {items.length == 0 && (
              <Grid item className={classes.noProductWrapper}>
                <Grid container alignItems="center" justifyContent="center">
                  <Typography variant="body1" className={classes.centerText}>
                    No Products Avaiable
                  </Typography>
                </Grid>
              </Grid>
            )}

            {/* Desktop products */}
            <Hidden mdDown>
              {items.map((item, index) => {
                let courseCover =
                  process.env.REACT_APP_API_URL +
                  process.env.REACT_APP_TEACHER_COURSE_PATH +
                  "cover/" +
                  item.course_cover;
                let courseLevels = item.course_level;
                return (
                  <Grid key={index} item className={classes.singleItem}>
                    <Grid container flexdirection="row" alignItems="center">
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        className={classes.itemImageWrapper}
                      >
                        <img src={courseCover} className={classes.itemImage} />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Grid
                          container
                          flexdirection="row"
                          justifyContent="space-between"
                          className={classes.containerCustomHieght}
                        >
                          <Grid item xs={12} sm={4}>
                            <Typography
                              className={classes.courseName}
                              variant="body1"
                            >
                              {item.course_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Typography
                              className={classes.courseName}
                              variant="body2"
                            >
                              {item.course_category}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <Typography
                              className={classes.courseName}
                              variant="body2"
                            >
                              ${item.course_tution_fee} HK
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            {/* <Typography className={classes.courseName} variant="body2">${item.discounted_price} HK</Typography> */}
                            <Typography
                              className={classes.courseAccessCode}
                              variant="body2"
                            >
                              {item.access_code}
                            </Typography>
                          </Grid>
                          {/* <Grid item xs={2}>
                                                        <Typography className={classes.courseName} variant="body2">{courseLevels}</Typography>
                                                    </Grid> */}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Grid
                          container
                          flexdirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Link
                            target="_blank"
                            className={classes.searchLink}
                            onClick={() => {
                              localStorage.setItem("productId", item.course_id);
                              localStorage.setItem("productImage", courseCover);
                            }}
                            to={{
                              pathname:
                                "/product/" +
                                (item.course_tution_fee == null ||
                                item.course_tution_fee == ""
                                  ? "view"
                                  : "preview") +
                                "/" +
                                item.course_name +
                                "/" +
                                item.course_id,
                              state: {
                                image: courseCover,
                                id: item.course_id,
                              },
                            }}
                          >
                            <Mui.Tooltip placement="top" title="View">
                              <Button
                                style={{
                                  color: "white",
                                  textDecoration: "none",
                                }}
                                className={classes.viewAction}
                              >
                                <Icon className={classes.actionIcon}>
                                  visibility
                                </Icon>
                              </Button>
                            </Mui.Tooltip>
                          </Link>
                          <Mui.Tooltip placement="top" title="Edit">
                            <Button
                              style={{ color: "white", textDecoration: "none" }}
                              className={classes.editAction}
                            >
                              <EditIcon
                                className={classes.actionIcon}
                                onClick={(e) =>
                                  editIconClick(
                                    e,
                                    item,
                                    "product/preview/" +
                                      item.course_name +
                                      "/" +
                                      item.course_id,
                                    item.access_code
                                  )
                                }
                              />
                            </Button>
                          </Mui.Tooltip>
                          <Mui.Tooltip placement="top" title="Delete">
                            <Button
                              style={{ color: "white", textDecoration: "none" }}
                              className={classes.deleteAction}
                            >
                              <DeleteOutline
                                className={classes.actionIcon}
                                onClick={(e) =>
                                  deleteIconClick(e, item.course_id)
                                }
                              />
                            </Button>
                          </Mui.Tooltip>
                          <Mui.Tooltip
                            placement="top"
                            title="Share this course"
                          >
                            <Button
                              style={{ color: "white", textDecoration: "none" }}
                              className={classes.shareAction}
                            >
                              <Mui.Icon
                                className={classes.actionIcon}
                                onClick={(e) =>
                                  shareProduct(
                                    e,
                                    "product/preview/" +
                                      item.course_name +
                                      "/" +
                                      item.course_id,
                                    item.access_code,
                                    item.course_id
                                  )
                                }
                              >
                                share
                              </Mui.Icon>
                            </Button>
                          </Mui.Tooltip>
                          <Mui.Tooltip
                            placement="top"
                            title="View class schedule"
                          >
                            <Link
                              to={
                                "/classes/" +
                                item.course_name +
                                "/" +
                                item.course_id
                              }
                              style={{ textDecoration: "none" }}
                            >
                              <Button
                                style={{
                                  color: "white",
                                  textDecoration: "none",
                                  backgroundColor: "#60974A",
                                }}
                                className={classes.shareAction}
                              >
                                <Mui.Icon className={classes.actionIcon}>
                                  schedule
                                </Mui.Icon>
                              </Button>
                            </Link>
                          </Mui.Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Hidden>
            {/* Mobile products */}
            <Hidden smUp>
              <Grid item xs={12}>
                <Grid container>
                  {items.map((item, index) => {
                    let courseCover =
                      process.env.REACT_APP_API_URL +
                      process.env.REACT_APP_TEACHER_COURSE_PATH +
                      "cover/" +
                      item.course_cover;
                    let courseLevels = item.course_level;
                    return (
                      <SmallProduct
                        item={item}
                        index={index}
                        courseCover={courseCover}
                        courseLevels={courseLevels}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Hidden>
            {/* iPad products */}
            <Hidden smDown lgUp>
              <Grid item xs={12}>
                <Grid container>
                  {items.map((item, index) => {
                    let courseCover =
                      process.env.REACT_APP_API_URL +
                      process.env.REACT_APP_TEACHER_COURSE_PATH +
                      "cover/" +
                      item.course_cover;
                    let courseLevels = item.course_level;
                    return (
                      <MediumProduct
                        item={item}
                        index={index}
                        courseCover={courseCover}
                        courseLevels={courseLevels}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        )}
      </>
    );
  };

  const ViewButtons = () => {
    return (
      <>
        <Mui.Tooltip placement="top" title="Table View">
          <Mui.Icon
            onClick={() => handleViewChnage("list")}
            className={`${viewStyle == "list" && classes.active} ${
              classes.icon
            }`}
          >
            view_headline
          </Mui.Icon>
        </Mui.Tooltip>
        <Mui.Tooltip placement="top" title="Grid View">
          <Mui.Icon
            onClick={() => handleViewChnage("grid")}
            className={`${viewStyle == "grid" && classes.active} ${
              classes.icon
            }`}
          >
            grid_on
          </Mui.Icon>
        </Mui.Tooltip>
      </>
    );
  };

  const Filters = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;
    return (
      <>
        <Mui.Tooltip placement="top" title="Sort by">
          <Button
            className="filter-btn"
            aria-describedby={id}
            onClick={handleClick}
          >
            <Icon>sorter</Icon>
          </Button>
        </Mui.Tooltip>
        <Popper
          className="courseFilter"
          placement={"bottom-end"}
          id={id}
          open={open}
          anchorEl={anchorEl}
        >
          <MenuItem
            onClick={(e) => {
              handleSorting("course_name", "ASC");
              handleToggle();
            }}
          >
            <Icon className="sort-icon">
              {sortBy == "course_name"
                ? "radio_button_checked"
                : "radio_button_unchecked"}
            </Icon>
            Alphabetical
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              handleSorting("course_id", "DESC");
              handleToggle();
            }}
          >
            <Icon className="sort-icon">
              {sortBy == "course_id"
                ? "radio_button_checked"
                : "radio_button_unchecked"}
            </Icon>
            Newest to Oldest
          </MenuItem>
        </Popper>
      </>
      // <>
      //     <Button
      //         ref={anchorRef}
      //         id="sort-button"
      //         aria-controls={sorterOpen ? 'sort-menu' : undefined}
      //         aria-haspopup="true"
      //         aria-expanded={sorterOpen ? 'true' : undefined}
      //         onClick={handleToggle}
      //     >
      //         <Icon>sort</Icon>
      //     </Button>
      //     <Menu elevation={0}
      //         open={sorterOpen}
      //         anchorOrigin={{
      //             vertical: 'bottom',
      //             horizontal: 'right',
      //         }}
      //         transformOrigin={{
      //             vertical: 'top',
      //             horizontal: 'right',
      //         }} id="sort-menu" placeholder="Sort by" name="sort-by" >
      //         <MenuItem onClick={(e) => { handleSorting('course_id', 'DESC'); handleToggle() }}>Newest to Oldest</MenuItem>
      //         <MenuItem onClick={(e) => { handleSorting('course_name', 'ASC'); handleToggle() }}>Alphabetical</MenuItem>
      //     </Menu>
      // </>
    );
  };

  const handleTabChange = (event, tab) => {
    var statuses = {
      inactive: 0,
      active: 1,
      deleted: -1,
    };
    console.log(statuses[tab]);
    var sort = {
      orderBy: sortBy,
      by: sortOrder,
    };
    getProducts(statuses[tab], JSON.stringify(sort));
    setActiveTab(tab);
  };

  const handleSorting = (orderBy, by = null) => {
    setSortBy(orderBy);

    if (by == null) setSortOrder(sortOrder == "ASC" ? "DESC" : "ASC");
    else setSortOrder(by);

    var sort = {
      orderBy: orderBy,
      by: sortOrder,
    };
    var statuses = {
      active: 1,
      inactive: 0,
      deleted: -1,
    };
    console.log(statuses[activeTab]);
    getProducts(statuses[activeTab], JSON.stringify(sort));
  };

  const handleSearch = (e) => {
    console.log(e.target.value);
    var statuses = {
      active: 1,
      inactive: 0,
      deleted: -1,
    };
    if (e.keyCode == 13) {
      setLoading(true);
      getProducts(statuses[activeTab], null, e.target.value);
    } else if (e.target.value.length == 0) {
      setLoading(true);
      getProducts(statuses[activeTab], null, null);
    }
  };

  const getProducts = (
    status = 1,
    orderBy = null,
    searchQuery = null,
    limit = 10,
    offset = 0
  ) => {
    ProductService.getProductsByTeacherId(
      localStorage.getItem("user_id"),
      status,
      orderBy,
      searchQuery,
      limit,
      offset
    ).then(
      (res) => {
        setLoading(false);
        setTotalPage(Math.ceil(res.data.count / limit));
        setItems(res.data.products);
        setMemoryUsage(res.data);
        // const addNew = new URLSearchParams(location.search).get('add-new')
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const updateProductList = () => {
    var statuses = {
      active: 1,
      inactive: 0,
      deleted: -1,
    };
    var productOffset = productPage * limit - limit;
    history.push("/my-products/all");
    // setShowAdd('all')
    setAddProductPopup(false);
    getProducts(statuses[activeTab], null, null, limit, productOffset);
    // setActiveTab(statuses[activeTab])
    // return <Redirect to='/my-products/all' />
    // getProducts(1, null)
  };

  const AlertMessage = () => {
    return (
      <Snackbar
        open={productAddSuccess}
        onClose={() => {
          setProductAddSuccess(false);
        }}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={() => {
            setProductAddSuccess(false);
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    );
  };

  const DeleteConfirmation = () => {
    return (
      <Dialog
        onBackdropClick={handleDeletePopupClose}
        open={deletePopup}
        onClose={handleDeletePopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ color: "#cc2626" }} id="alert-dialog-title">
          {"Delete Confirmation?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#ebebeb" }}
            onClick={handleDeletePopupClose}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "#cc2626", color: "white" }}
            onClick={handleDeletePopupConfirmation}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handlePageChange = (event, value) => {
    setProductPage(value);
    var statuses = {
      active: 1,
      inactive: 0,
      deleted: -1,
    };
    var productOffset = value * limit - limit;
    getProducts(statuses[activeTab], null, null, limit, productOffset);
  };

  const handleCloseSharePopUp = () => {
    setShareProductPopUp(false);
  };

  return (
    <Container maxWidth="lg">
      {shareProductPopUp && (
        <ShareProduct
          productID={productID}
          closeShare={handleCloseSharePopUp}
          url={productLink}
          accessCode={productAccessCode}
          item={items}
        />
      )}
      <Grid container alignItems="flex-start">
        <ProductPopup />
        <DeleteConfirmation />
        <AlertMessage />
        <Grid item xs={12} className={classes.cartWrapper}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid>
              <h3 className={classes.pageTitles}>My Courses</h3>
              {/* <FileUsage
                total={usage.total_usage}
                max={usage.allowed_usage}
                variant="popover"
              /> */}
            </Grid>
            <Grid item>
              <Grid
                container
                justifyContent="center"
                style={{ position: "relative" }}
                alignItems="center"
              >
                <Hidden smDown>
                  <h4 className={classes.pageTitles}>
                    {items.length} {items.length > 1 ? "Products" : "Product"}
                  </h4>
                </Hidden>
                <Button
                  className={classes.creationBtn}
                  onClick={handleAddProductBtnClick}
                >
                  <Icon style={{ marginRight: 8, fontSize: 18 }}>
                    add_circle
                  </Icon>
                  Create Course
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* TabControls */}
          <Mul.TabContext value={activeTab}>
            <Mui.Box
              style={{ borderBottom: "solid 1px #e7e7e7", marginTop: 20 }}
            >
              <Mul.TabList onChange={handleTabChange}>
                <Mui.Tab
                  label="Active"
                  style={{
                    borderBottom: "solid 3px divider",
                    fontSize: 14,
                    textTransform: "Capitalize",
                  }}
                  value="active"
                ></Mui.Tab>
                <Mui.Tab
                  label="Inactive"
                  style={{ fontSize: 14, textTransform: "Capitalize" }}
                  value="inactive"
                ></Mui.Tab>
                <Mui.Tab
                  label="Deleted"
                  style={{ fontSize: 14, textTransform: "Capitalize" }}
                  value="deleted"
                ></Mui.Tab>
              </Mul.TabList>
            </Mui.Box>
            <Mul.TabPanel className={classes.noPaddingMobile} value="active">
              {/* <Hidden smDown> */}
              <SearchBar handleSearch={handleSearch} />
              {/* </Hidden> */}
              <Grid
                style={{ marginTop: 15, marginBottom: 8 }}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Mul.Pagination
                  className={classes.pagination}
                  count={totalPage}
                  page={productPage}
                  onChange={handlePageChange}
                />
                <Grid item className={classes.iconsView}>
                  <Hidden smDown>
                    <ViewButtons />
                  </Hidden>
                  <Filters />
                </Grid>
              </Grid>
              {/* Active Products Tab */}
              <Products />
            </Mul.TabPanel>
            <Mul.TabPanel className={classes.noPaddingMobile} value="inactive">
              {/* <Hidden smDown> */}
              <SearchBar handleSearch={handleSearch} />
              {/* </Hidden> */}
              <Grid
                style={{ marginTop: 15, marginBottom: 8 }}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Mul.Pagination
                  className={classes.pagination}
                  count={totalPage}
                  page={productPage}
                  onChange={handlePageChange}
                />
                <Grid item className={classes.iconsView}>
                  <Hidden smDown>
                    <ViewButtons />
                  </Hidden>
                  <Filters />
                </Grid>
              </Grid>
              {/* In Active Products Tab */}
              <Products />
            </Mul.TabPanel>
            <Mul.TabPanel className={classes.noPaddingMobile} value="deleted">
              {/* <Hidden smDown> */}
              <SearchBar handleSearch={handleSearch} />
              {/* </Hidden> */}
              <Grid
                style={{ marginTop: 15, marginBottom: 8 }}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Mul.Pagination
                  className={classes.pagination}
                  count={totalPage}
                  page={productPage}
                  onChange={handlePageChange}
                />
                <Grid item className={classes.iconsView}>
                  <Hidden smDown>
                    <ViewButtons />
                  </Hidden>
                  <Filters />
                </Grid>
              </Grid>
              {/* Deleted Products Tab */}
              <Products />
            </Mul.TabPanel>
          </Mul.TabContext>
        </Grid>
      </Grid>
    </Container>
  );
}
